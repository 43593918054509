import React from 'react'
import { Translate } from 'react-localize-redux'
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

TagManager.initialize({
	gtmId: 'GTM-NDG8H5M',
	customURL: 'https://cc.lohncheck.ch/gtm.js',
})

const Privacy = () => (
	<Translate>
		{({ activeLanguage }) =>
			activeLanguage?.code === 'fr' ? (
				<>
					<h1>Déclaration relative à la protection des données</h1>
					<p>
						Cette déclaration relative à la protection de Matto-Group
						AG (<b>« Matto-Group »</b>) a pour but de vous indiquer
						les données collectées à votre sujet, la raison pour
						laquelle nous les traitons et à qui nous les
						transmettons, de manière à garantir un traitement des
						données transparent et conforme à la loi. Nous mettons à
						disposition les informations requises pour faire valoir
						vos droits en vertu de la loi suisse sur la protection
						des données (LPD) et - dans la mesure où il est
						applicable - en vertu du règlement général de l'UE sur
						la protection des données (RGPD). Si vous avez d'autres
						questions concernant le traitement de vos données
						personnelles ou relatives à la protection des données,
						n'hésitez pas à nous contacter en utilisant les
						coordonnées ci-dessous.
					</p>
					<h2>1. Identité et coordonnées du responsable</h2>
					<p>
						Matto-Group AG, General-Guisan-Str. 6, CH – 6300 Zug est
						responsable du traitement des données décrit ci-dessous.
						Si vous avez des questions relatives à la protection des
						données, vous pouvez contacter l'adresse suivante :
					</p>
					<p>
						Matto-Group AG <br />
						General-Guisan-Str. 6 <br />
						CH – 6300 Zug
					</p>
					<p>
						E-mail :{' '}
						<a href="mailto:datenschutz@lohncheck.ch">
							datenschutz@lohncheck.ch
						</a>
					</p>
					<p>
						Les demandes de suppression de données peuvent être
						soumises via{' '}
						<a
							href="https://lohncheck.ch/fr/daten-loeschen"
							target="_blank"
							rel="noopener noreferrer"
						>
							cette page
						</a>
						.
					</p>
					<h2>
						2. Acquisition des données personnelles ; catégories de
						données
					</h2>
					<p>
						Nous traitons principalement les données personnelles
						que vous nous fournissez lors de l'utilisation de nos
						sites web ou plateformes. Cela comprend des informations
						vous concernant (par exemple, âge, date de naissance,
						nationalité, lieu de résidence, état civil, adresse
						e-mail), votre formation (par exemple, diplômes,
						établissements fréquentés), votre expérience
						professionnelle (par exemple, niveau de carrière,
						expérience professionnelle, etc.) ainsi que votre
						employeur (par exemple, entreprise, secteur d'activité,
						nombre d'employés, secteur, type d'entreprise) et votre
						salaire. Si vous nous fournissez les données
						personnelles d'autres personnes, veuillez vous assurer
						que ces personnes connaissent cette déclaration relative
						à la protection des données et nous fournissent leurs
						données personnelles uniquement si vous y vez été
						autorisé et si ces données personnelles sont correctes.
						Dans les limites légales, nous pouvons être également
						amenés à faire appel à certaines données personnelles
						vous concernant à partir de sources accessibles au
						public (par exemple, bases de données publiques,
						internet, etc.). D'autres données sont automatiquement
						collectées et traitées lorsque vous visitez le site web
						(par exemple, les adresses IP et autres informations
						liées à l'utilisation de ce dernier).
					</p>
					<h2>3. Finalités du traitement des données</h2>
					<h3>Exécution de nos prestations de services</h3>
					<p>
						Nous utilisons vos données personnelles - sur la base de
						l'article 6 al. 1 point b du RGPD - principalement pour
						fournir nos services en ligne, c'est-à-dire notamment
						pour effectuer des calculs et des analyses de salaires
						sur nos sites web ou nos plateformes.
					</p>
					<p>
						En outre, nous utilisons vos données personnelles - sur
						la base de nos intérêts légitimes (art. 6 al.1 point f
						du RGPD) et, si nécessaire, de votre consentement (art.
						6 al. 1 point a du RGPD) – aux fins suivantes :
					</p>
					<h3>Envoi de newsletters</h3>
					<p>
						Nous utilisons vos données personnelles (notamment votre
						adresse e-mail) pour vous envoyer des informations sur
						des produits et services personnalisés ainsi que des
						offres d'emploi de nos partenaires publicitaires via la
						newsletter. Vous pouvez vous opposer à cet envoi à tout
						moment en adressant un e-mail à{' '}
						<a href="mailto:datenschutz@lohncheck.ch">
							datenschutz@lohncheck.ch
						</a>{' '}
						ou en cliquant sur le lien de désabonnement indiqué dans
						le message.
					</p>
					<h3>Publicité propre et de tiers</h3>
					<p>
						Sur la base des informations que vous fournissez sur
						notre site web ou notre plateforme (voir point 2), nous
						- ou des tiers auxquels nous transmettons ces données
						sous forme pseudonymisée (c'est-à-dire que ces tiers ne
						peuvent obtenir aucune information sur votre identité, à
						moins que vous ne soyez déjà connu de ces tiers, par
						exemple en raison d'une connexion) - pouvons être amenés
						à vous montrer certaines offres personnalisées dédiées à
						un groupe cible (par exemple, groupe professionnel ou
						groupe d'âge) sur notre site web ou sur d'autres sites
						web. Le fait de vous montrer des offres provenant de nos
						partenaires publicitaires nous permet de suivre la
						manière dont vous utilisez les liens correspondants afin
						de mieux comprendre vos intérêts et d'individualiser
						davantage les offres. Vous pouvez à tout moment vous
						opposer à l'utilisation de vos données personnelles à
						des fins publicitaires en cliquant sur le{' '}
						<a
							href="https://lohncheck.ch/fr/daten-loeschen"
							target="_blank"
							rel="noopener noreferrer"
						>
							lien de désinscription
						</a>
						.
					</p>
					<h3>
						Informations sur les achats programmatiques aux enchères
					</h3>
					<p>
						<i>1PlusX</i>
					</p>
					<p>
						Nous travaillons avec 1plusX. Il s'agit d'une
						plate-forme de gestion de données et de marketing
						prédictif qui utilise des algorithmes artificiellement
						intelligents pour calculer des données et des
						informations afin de rendre l'activité numérique des
						entreprises considérablement plus efficace. En
						fusionnant les informations de tous les canaux et points
						de contact, la plate-forme exploitée par 1plusX
						reconnaît les modèles de comportement des utilisateurs,
						qui sont convertis en prédictions en temps réel. À
						l'aide de ces prévisions, les entreprises des secteurs
						des médias, des télécommunications et du commerce
						électronique peuvent optimiser de manière ciblée les
						activités de publicité, de contenu et de vente. Dans le
						même temps, elles sont toujours en possession de leurs
						données, qui sont uniquement utilisées aux fins de
						chaque entreprise. Les adresses e-mail sont utilisées
						dans une data room pour fusionner les profils
						utilisateurs et ainsi améliorer les modèles.
					</p>
					<p>
						<i>UID2</i>
					</p>
					<p>
						Un jeton UID2 est un identifiant permanent fourni et
						contrôlé par l'utilisateur pour la publicité numérique.
						Un jeton peut uniquement être créé et utilisé dans
						l'écosystème de la publicité numérique si l'utilisateur
						le permet via une première partie de confiance. La
						création du jeton UID2 dépend d'un certain nombre de
						fonctions techniques et de mesures de sécurité, conçues
						pour protéger la vie privée des consommateurs de manière
						complexe.
					</p>
					<p>
						Il existe trois infrastructures et services techniques
						différents qui prennent en charge la conception
						technique actuelle de l'UID2 et respectent les principes
						de la conception sécurisée :
					</p>
					<ol>
						<li>
							Service d'administration : un utilitaire centralisé
							qui gère et distribue les clés de chiffrement /
							déchiffrement et les Salt Buckets pour l'écosystème
							UID2 distribué. Ceux-ci sont nécessaires pour que
							les opérateurs puissent remplir leurs rôles et que
							les participants UID2 puissent accéder aux jetons
							UID2.
						</li>
						<li>
							Services opérateurs : les opérateurs génèrent et
							gèrent les jetons UID2. De nombreux opérateurs sont
							pris en compte. À l'aide des clés de chiffrement et
							des conteneurs Salt du service d'administration, les
							opérateurs traduisent les données fournies par
							l'utilisateur (e-mail ou numéro de téléphone) en
							jetons UID2 sécurisés qui peuvent être utilisés pour
							permettre un certain nombre de résultats
							d'adressabilité.
						</li>
						<li>
							Services axés sur l'utilisateur et responsabilité
							technique : il existe également des API qui offrent
							aux utilisateurs transparence et contrôle en
							communiquant leurs préférences à l'administration et
							aux opérateurs de manière vérifiable.
						</li>
					</ol>
					<p>
						Enfin, nous pouvons utiliser vos données personnelles
						sur la base de nos intérêts légitimes (art. 6, al.1,
						point f du RGPD) aux fins suivantes :
					</p>
					<ul>
						<li>
							<u>Optimisation :</u> nous utilisons vos données
							personnelles à des fins d'analyse en vue d'améliorer
							la qualité de nos services (notamment notre modèle
							statistique de calcul des salaires) ainsi que la
							convivialité et les fonctionnalités de notre site
							internet. Si nous vous montrons des offres de tiers,
							nous pouvons suivre la manière dont vous utilisez
							les liens correspondants afin d'améliorer nos
							services et d'établir des statistiques.
						</li>
						<li>
							<u>Application de la loi :</u> nous pouvons utiliser
							vos données personnelles pour faire valoir ou
							défendre des droits légaux dans le cadre de litiges
							et de procédures officielles.
						</li>
						<li>
							<u>Prévention des infractions pénales :</u> nous
							pouvons utiliser vos données personnelles pour
							prévenir et enquêter sur des infractions pénales et
							d'autres comportements répréhensibles (par exemple,
							l'analyse des données pour lutter contre la fraude).
						</li>
						<li>
							<u>Disponibilité et sécurité :</u> nous pouvons
							utiliser vos données personnelles pour assurer la
							disponibilité et la sécurité de notre site web.
						</li>
						<li>
							<u>Transactions de l'entreprise :</u> nous pouvons
							utiliser vos données personnelles pour préparer et
							traiter les transactions de l'entreprise.
						</li>
					</ul>
					<h2>4. Outils d'analyse</h2>
					<p>
						<i>Cookies</i>
					</p>
					<p>
						Nous utilisons des cookies sur notre site afin de
						pouvoir vous reconnaître ou reconnaître votre appareil
						(ordinateur, téléphone portable, tablette, etc.) lorsque
						vous visitez à nouveau le site (à l'aide d'un code
						stocké dans votre navigateur) et afin de pouvoir vous
						garantir une expérience de navigation sans faille pour
						mieux comprendre comment vous pouvez utiliser notre site
						web et proposer des offres personnalisées.
					</p>
					<p>
						Sur notre site web, nous pouvons utiliser des éléments
						et des services de tiers (par exemple des offres
						d'emploi, des services de retraite et d'assurance) qui
						nous fournissent des statistiques d'utilisation qui
						servent à afficher de la publicité ou qui vous donnent
						accès aux réseaux sociaux et autres offres de tiers. En
						conséquence, ces tiers peuvent collecter des données
						personnelles vous concernant dans une mesure limitée, à
						condition qu'ils vous reconnaissent ou identifient votre
						appareil, par exemple en utilisant leurs propres cookies
						ou identifiants, etc. Cependant, si vous cliquez sur le
						lien d'une offre provenant d'un tiers, nous ne sommes
						pas responsables du traitement ultérieur de vos données
						personnelles, c'est-à-dire que vous devez accepter celui
						du tiers. Nous pouvons transmettre les informations sur
						votre utilisation de notre site web à nos partenaires
						pour les réseaux sociaux, la publicité et l'analyse. Ils
						peuvent combiner ces informations avec d'autres données
						que vous leur avez fournies ou qu'ils ont collectées
						dans le cadre de votre utilisation des services. Enfin,
						nous pouvons envoyer notre newsletter et d'autres
						e-mails commerciaux avec des liens personnels afin de
						mieux comprendre comment vous utilisez nos offres et
						maintenir nos cookies à jour.
					</p>
					<p>
						Vous avez la possibilité à tout moment de configurer
						votre navigateur ou votre programme de messagerie de
						manière à ce que les cookies soient rejetés, enregistrés
						seulement pour une session ou supprimés prématurément.
						Si vous bloquez les cookies, il est toutefois possible
						que certaines fonctionnalités ne puissent pas être
						utilisées.
					</p>
					<p>
						<i>Facebook Custom Audience avec adresses e-mail</i>
					</p>
					<p>
						Facebook Custom Audiences est une campagne de
						remarketing dans laquelle l'annonceur utilise un
						algorithme pour chiffrer les adresses e-mail de ses
						clients qui ont été prélevées et collectées à cette fin,
						et ces sommes de contrôle non personnelles (codes de
						hachage) sont envoyées au serveur de téléchargement de
						Facebook. Facebook peut comparer ces données transmises
						avec son inventaire d'identifiants d'utilisateurs
						chiffrés et enregistrer les correspondances dans le
						compte client de l'annonceur en tant que « Customer
						Audiences » (= groupe cible défini par l'utilisateur)
						dans le cadre de campagnes marketing ciblées. Une fois
						la correspondance réalisées, toutes les valeurs de
						hachage téléchargées sont à nouveau supprimées.
					</p>
					<p>
						Vous trouverez de plus amples informations sur l'étendue
						de la collecte, le traitement ultérieur et l'utilisation
						des données par Facebook Custom Audiences dans les
						directives de protection des données de Facebook à
						l'adresse suivante :{' '}
						<a
							href="https://www.facebook.com/privacy/explanation"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.facebook.com/privacy/explanation
						</a>
						. La politique d'utilisation de Custom Audiences est
						disponible ici :{' '}
						<a
							href="https://www.facebook.com/ads/manage/customaudiences/tos.php"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.facebook.com/ads/manage/customaudiences/tos.php
						</a>
						. Les informations relatives à la collecte et
						l'utilisation d'informations pour la publicité en ligne
						destinée à des groupes cibles spécifiques sont
						disponibles ici :{' '}
						<a
							href="https://www.facebook.com/ads/website_custom_audiences/"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.facebook.com/ads/website_custom_audiences/
						</a>
					</p>
					<p>
						<i>Google Custom Match avec adresses e-mail</i>
					</p>
					<p>
						Avec Google Customer Match, les annonceurs importent
						leurs propres listes d'adresses e-mail de clients dans
						leur compte Google AdWords, qui sont chiffrées à l'aide
						d'un algorithme. Customer Match compare les listes de
						diffusion anonymes dans AdWords avec la propre base de
						données des utilisateurs enregistrés et peut ainsi créer
						des publicités ciblées pour la recherche Google, YouTube
						et Gmail et optimiser les campagnes de manière
						indépendante. En outre, Google peut rechercher des
						clients similaires ayant des intérêts similaires sur la
						base des listes d'utilisateurs téléchargées.
					</p>
					<p>
						Avec la fonction « comparaison client », Google crée une
						source d'informations centrale pour le reciblage des
						clients (les visiteurs sont enregistrés sur un site web
						et ils reçoivent de la publicité ciblée sur d'autres
						sites web). La politique d'utilisation de la fonction «
						comparaison client » s'applique aux données fournies par
						l'annonceur :{' '}
						<a
							href="https://support.google.com/adwordspolicy/answer/6299717?hl=de"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://support.google.com/adwordspolicy/answer/6299717?hl=de
						</a>
						.
					</p>
					<p>
						Pour plus d'informations, veuillez vous référer à la
						politique de confidentialité de Google à l'adresse
						suivante :{' '}
						<a
							href="https://www.google.com/intl/de/policies/privacy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.google.com/intl/de/policies/privacy/
						</a>
						. Vous pouvez modifier les paramètres des données
						personnelles et de la confidentialité sous Google « Mon
						compte » :{' '}
						<a
							href="https://myaccount.google.com/intro?hl=de"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://myaccount.google.com/intro?hl=de
						</a>
						.
					</p>
					<h2>5. Destinataires des données personnelles</h2>
					<p>
						<i>Prestataire de services</i>
					</p>
					<p>
						Nous pouvons faire appel à des prestataires de services
						(appelés « sous-traitants ») pour traiter vos données
						personnelles conformément aux points 3 et 4. Il s'agit
						notamment de prestataires de services informatiques, de
						statisticiens pour la modélisation (ex : calculateurs de
						salaires), de créateurs de statistiques d'utilisation et
						de prestataires de services marketing et de newsletter..
						Ces prestataires traitent vos données personnelles en
						notre nom et exclusivement dans la mesure où nous sommes
						autorisés à l'effectuer nous-mêmes. Ils sont soumis à
						des contrôles en matière de respect de la sécurité des
						données et tenus à la confidentialité.
					</p>
					<p>
						<i>Tiers</i>
					</p>
					<p>
						Nous divulguons également vos données personnelles à nos
						partenaires publicitaires qui ne traitent pas les
						données en notre nom mais à leurs propres fins et donc
						sous leur propre responsabilité. Les données
						personnelles que nous divulguons à nos partenaires
						publicitaires sont exclusivement votre adresse e-mail «
						hachée » (c'est-à-dire pseudonymisée), afin que nos
						partenaires publicitaires puissent vous reconnaître en
						les comparant avec des valeurs de hachage obtenues par
						ailleurs et afficher des campagnes publicitaires ou
						celles de tiers de manière personnalisée.
					</p>
					<h2>6. Communication à l'étranger</h2>
					<p>
						Les destinataires de vos données personnelles mentionnés
						au point 5 sont généralement situés en Suisse et en
						Allemagne ou dans d'autres pays de l'UE / EEE, mais
						peuvent potentiellement être situés n'importe où dans le
						monde.
					</p>
					<p>
						Si nous transmettons vos données personnelles à un
						destinataire situé dans un pays sans protection légale
						adéquate des données, nous assurons une protection
						appropriée des données par le biais de contrats adaptés
						(clauses contractuelles types de l'UE préalablement
						approuvées, émises ou reconnues par le PFPDT) ou nous
						nous appuyons sur les exceptions légales du
						consentement, le traitement des contrats,
						l'établissement, l'exercice ou l'exécution de droits
						légaux, l'intérêt public supérieur, les données
						personnelles publiées ou la nécessité de protéger votre
						intégrité. Vous pouvez à tout moment obtenir auprès de
						nos services une copie des garanties contractuelles
						mentionnées ici. Cependant, nous nous réservons le droit
						de noircir certains passages ou d'en fournir uniquement
						des extraits pour des raisons de protection des données
						ou de confidentialité.
					</p>
					<h2>7. Durée de conservation des données personnelles </h2>
					<p>
						Nous traitons vos données personnelles aussi longtemps
						que cela est nécessaire à l'accomplissement de nos
						obligations contractuelles et / ou légales ou à d'autres
						fins poursuivies par celui-ci, sous réserve de toute
						obligation légale de conservation et de documentation.
						Il est possible que des données personnelles soient
						conservées pendant la période au cours de laquelle des
						plaintes peuvent être déposées contre notre entreprise
						et dans la mesure où nous y sommes légalement tenus ou
						où des intérêts commerciaux légitimes l’exigent (à des
						fins de preuve et de documentation, par exemple). Dès
						que vos données personnelles ne sont plus nécessaires
						aux fins susmentionnées, elles sont en principe effacées
						et, dans la mesure du possible, rendues anonymes.
					</p>
					<h2>8. Protection des données</h2>
					<p>
						Nous prenons les mesures techniques et
						organisationnelles appropriées pour protéger vos données
						personnelles contre la perte, l'accès non autorisé et
						l'utilisation abusive (par exemple par le biais
						d'instructions internes, de formations, de solutions de
						sécurité informatique et réseau, de contrôles et de
						restrictions d'accès, de chiffrement des supports de
						données et de transferts, de pseudonymisation des
						données personnelles, etc.).
					</p>
					<h2>9. Vos droits</h2>
					<p>
						Vous disposez d'un droit d'information, de
						rectification, de suppression et de divulgation de
						certaines données personnelles dans le but de les
						transférer vers une autre entité. Veuillez noter,
						cependant, que nous nous réservons le droit d'appliquer
						les restrictions légales, par exemple si nous sommes
						obligés de stocker ou de traiter certaines données, si
						nous avons un intérêt supérieur ou si nous en avons
						besoin pour faire valoir des droits. Vous serez informé
						en amont des frais éventuels. Veuillez également noter
						que l'exercice de ces droits peut entrer en conflit avec
						les accords contractuels et avoir des conséquences
						telles qu'une résiliation prématurée du contrat ou des
						frais. Dans un tel cas, vous serez informé à l'avance,
						si cela n'est pas déjà réglementé contractuellement.
						L'exercice de ces droits requiert généralement une
						preuve claire de votre identité (par exemple au moyen
						d'une copie de votre pièce d'identité). Pour faire
						valoir vos droits, vous pouvez nous contacter à
						l'adresse ou au numéro de téléphone indiqués au point 1.
					</p>
					<p>
						Vous êtes autorisé à faire valoir vos droits devant les
						tribunaux ou déposer une plainte auprès de l'autorité
						compétente en matière de protection des données.
						L'autorité compétente en matière de protection des
						données en Suisse est le Préposé fédéral à la protection
						des données et à la transparence (
						<a
							href="http://www.edoeb.admin.ch"
							target="_blank"
							rel="noopener noreferrer"
						>
							http://www.edoeb.admin.ch
						</a>
						).
					</p>
					<h2>10. Modifications</h2>
					<p>
						Nous nous réservons le droit de modifier les clauses de
						cette déclaration relative à la protection des données à
						tout moment et sans préavis. La version actuelle publiée
						sur notre site internet s'applique.
					</p>
					<p>
						La déclaration relative à la protection des données a
						été traduite de l'allemand. En cas de doute, la version
						allemande s'applique.
					</p>
					<p>Version du 18.11.2021</p>
				</>
			) : (
				<>
					<h1>Datenschutzerklärung der Matto-Group GmbH</h1>
					<p>
						In dieser Datenschutzerklärung teilen wir, die Matto-Group
						AG („
						<b>Matto-Group</b>“), Ihnen mit, welche Daten wir über Sie erheben, warum wir diese bearbeiten
						und an wen wir diese weitergeben, damit unsererseits eine transparente und gesetzeskonforme
						Datenbearbeitung gewährleistet ist. Dabei geben wir Ihnen diejenigen Informationen mit, welche
						Sie benötigen, um Ihre Rechte nach der jeweils anwendbaren Datenschutzgesetzgebung, insbesondere
						nach dem Schweizer Datenschutzgesetz (DSG) und der EU-Datenschutzgrundverordnung (DSGVO),
						geltend machen zu können. Für allfällige weitere Fragen zur Bearbeitung Ihrer Personendaten
						sowie bei anderen datenschutzrechtlichen Anliegen stehen wir Ihnen gerne unter den nachstehenden
						Kontaktangaben zur Verfügung.
					</p>
					<h2>1. Identität und Kontaktdaten des Verantwortlichen; Vertreter in der EU</h2>
					<p>
						Verantwortlich für die hierunter beschriebenen Datenbearbeitungen ist die Matto-Group GmbH,
						General-Guisan-Str. 6, 6300 Zug. Bei allfälligen Fragen zum Datenschutz können Sie sich an
						folgende Kontaktadressen wenden:
					</p>
					<p>
						Matto-Group GmbH <br />
						General-Guisan-Str. 6 <br />
						6300 Zug
					</p>
					<p>
						E-Mail:{' '}
						<a href='mailto:datenschutz@lohncheck.ch'>
							datenschutz@lohncheck.ch
						</a>
					</p>
					<p>
						Unser Vertreter im EU-Raum (Art. 27 DSGVO) ist:{' '}
					</p>
					<p>
						Philip Isik
					</p>
					<h2>2. Beschaffung von Personendaten; Datenkategorien</h2>
					<p>
						Wir bearbeiten primär diejenigen Personendaten, welche Sie uns im Rahmen der Nutzung unserer
						Webseiten bzw. Plattformen bekanntgeben. Dabei handelt es sich insbesondere um Angaben zu Ihrer
						Person (z.B. Alter, Geburtsdatum, Nationalität, Wohnort, Zivilstand, E-Mail-Adresse), zu Ihrer
						Ausbildung (z.B. Diplome, Bildungsinstitution), zu Ihrer Berufserfahrung (z.B. Karrierelevel,
						Arbeitserfahrung etc.) sowie zu Ihrem Arbeitgeber (z.B. Firma, Branche, Anzahl Mitarbeiter,
						Ausrichtung, Gesellschaftsform) und zu Ihrem Gehalt. Wenn Sie uns Personendaten von anderen
						Personen zur Verfügung stellen, stellen Sie bitte sicher, dass diese Personen die vorliegende
						Datenschutzerklärung kennen und teilen Sie uns deren Personendaten nur mit, wenn Sie dies dürfen
						und diese Personendaten korrekt sind. Sofern und soweit zulässig, entnehmen wir auch öffentlich
						zugänglichen Quellen (z.B. öffentliche Datenbanken, Internet etc.) bestimmte Personendaten von
						Ihnen. Andere Daten werden im Rahmen Ihres Webseitenbesuchs automatisch gesammelt und bearbeitet
						(z.B. IP-Adressen und andere Nutzungsinformationen).
					</p>
					<h2>3. Zwecke der Datenbearbeitung</h2>
					<h3>Erbringung unserer Dienstleistungen</h3>
					<p>
						Wir verwenden Ihre Personendaten – gestützt auf Art. 6 Abs. 1 lit. b DSGVO – primär zur
						Erbringung unserer Online-Dienstleistungen, d.h. insbesondere zur Durchführung von
						Lohnberechnungen und -analysen auf unseren Webseiten bzw. Plattformen, zur Vermeidung von
						Redundanzen in unseren Trainingsdaten und zur Anzeige von relevanten Jobinseraten auf unseren
						Seiten.
					</p>
					<p>
						Überdies verwenden wir Ihre Personendaten – gestützt auf Ihre Einwilligung (Art. 6 Abs. 1 lit. a
						DSGVO) und unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) – zu folgenden Zwecken:
					</p>
					<h3>Newsletter-Versand</h3>
					<p>
						Wir verwenden Ihre Personendaten (insbesondere E-Mail-Adresse) dazu, um Ihnen Informationen zu
						personalisierten Produkten und Dienstleistungen sowie Stellenangeboten unserer Werbepartner per
						Newsletter zukommen zu lassen. Sie können diesem Versand jederzeit per E-Mail an{' '}
						<a href='mailto:datenschutz@lohncheck.ch'>
							datenschutz@lohncheck.ch
						</a>{' '}
						bzw. durch Betätigung des Abmelde-Links in der jeweiligen Mitteilung widersprechen.
					</p>
					<h3>Eigen- und Fremdwerbung und Werbung durch Dritte</h3>
					<p>
						Basierend auf Ihrer separaten Einwilligung können wir oder Dritte die von Ihnen auf unserer
						Webseite bzw. Plattform gemachten Angaben (vgl. Ziff. 2) zu Werbe- und Marketingzwecken nutzen.
						In Fällen, in denen wir Ihre Daten an unsere Partner wie beispielsweise Publisher zur
						Bearbeitung für ihre eigenen Zwecken weitergeben, sind diese für die weitere Bearbeitung Ihrer
						Daten verantwortlich, soweit Sie in der Einwilligungserklärung nicht anders informiert werden.
						Weitere Informationen zu solchen Datenbearbeitungen finden Sie in den gesonderten
						Einwilligungserklärungen und in den Datenschutzerklärungen unserer Partner. Wir können Ihre
						Daten auch in pseudonymisierter Form an unsere Werbetechnologie-Partner wie z.B. Decentric oder
						an Publisher übermitteln (d.h. diesen Dritten sind keine Rückschlüsse auf Ihre Person möglich,
						es sei denn, Sie sind diesen Dritten z.B. aufgrund eines Logins bereits bekannt), um Ihnen als
						Teil einer bestimmten Zielgruppe (z.B. Berufsgruppe oder Altersklasse) personalisierte Angebote
						auf unserer Webseite oder auf anderen Webseiten anzuzeigen. Falls wir Ihnen Angebote unserer
						Werbepartner anzeigen, können wir nachverfolgen, wie Sie die entsprechenden Links nutzen, um
						Ihre Interessen besser zu verstehen und die Angebote weiter zu individualisieren. Sie können der
						Verwendung Ihrer Personendaten durch uns zu Werbezwecken jederzeit per E-Mail an
						{' '}
						<a href='mailto:datenschutz@lohncheck.ch'>
							datenschutz@lohncheck.ch
						</a>{' '}bzw. durch Betätigung des
						{' '}
						<a
							href='https://lohncheck.ch/de/daten-loeschen'
							target='_blank'
							rel='noopener noreferrer'
						>
							Abmelde-Links
						</a>{' '}
						widersprechen.
					</p>
					<h3>
						Informationen zum programmatischen Einkauf auf Auktionen
					</h3>
					<p>
						<i>1PlusX</i>
					</p>
					<p>
						Wir arbeiten mit 1plusX zusammen. Das ist eine Data Management- und Predictive Marketing
						Platform, die mittels künstlich intelligenter Algorithmen Daten und Informationen berechnet, um
						das digitale Geschäft von Unternehmen messbar erfolgreicher zu machen. Durch das Zusammenführen
						von Informationen aus sämtlichen Kanälen und Touchpoints, erkennt die von 1plusX betriebene
						Plattform Verhaltensmuster von Nutzern, die in Echtzeit in Vorhersagen umgewandelt werden.
						Mithilfe dieser Vorhersagen können Unternehmen aus der Media-, Telekommunikations- und
						E-Commerce-Branche gezielt Werbung, Inhalte und Verkaufsaktivitäten optimieren. Dabei bleiben
						sie stets im Besitz ihrer Daten, die lediglich für die Ziele des jeweiligen Unternehmens
						eingesetzt werden. Emailadressen werden in einem Dataroom genutzt, um Nutzerprofile
						zusammenzuführen und damit die Modelle zu verbessern.
					</p>
					<p>
						<i>Decentric</i>
					</p>
					<p>
						Decentric bietet eine Lösung namens Data Clean Room, die es ermöglicht, dass verschiedene
						Parteien aggregierte Daten analysieren können, ohne die tatsächlichen personenbezogenen Daten
						offenzulegen. Dieses System ermöglicht es uns, Daten aus verschiedenen Quellen (typischerweise
						Publisher wie TX/ Goldbach, Ringier oder Audienzz) zu nutzen, um Erkenntnisse zu gewinnen, ohne
						dass die Privatsphäre unserer Nutzer gefährdet wird. Die Daten werden in einem hochsicheren und
						verschlüsselten Umfeld analysiert, wobei eine der fortschrittlichsten
						Verschlüsselungstechnologien verwendet wird. Dadurch wird sichergestellt, dass die Daten nicht
						wiedererkannt oder auf individuelle Nutzer zurückgeführt werden können.
					</p>
					<p>
						<i>UID2 oder EUID</i>
					</p>
					<p>
						Ein UID2 oder EUID-Token ist ein vom Nutzer bereitgestellter und kontrollierter, dauerhafter
						Identifikator für digitale Werbung. Ein Token kann nur erstellt und innerhalb des digitalen
						Werbe-Ökosystems verwendet werden, wenn der Nutzer dies über eine erste Partei, der er vertraut,
						erlaubt. Die Erstellung des UID2-Tokens hängt von einer Reihe technischer Funktionen und
						Sicherheitsmassnahmen ab, die zum Schutz der Privatsphäre der Verbraucher kompliziert gestaltet
						sind.
					</p>
					<p>
						Es gibt drei verschiedene technische Infrastrukturen und Dienste, die das derzeitige technische
						Design von UID2 unterstützen und die Prinzipien des sicheren Designs aufrechterhalten:
					</p>
					<ol>
						<li>
							Admin-Dienst: Ein zentrales Dienstprogramm, das Verschlüsselungs-/Entschlüsselungsschlüssel
							und Salt Buckets für das UID2-Ökosystem verwaltet und verteilt. Diese werden benötigt, damit
							die Betreiber ihre Rollen erfüllen und die UID2-Teilnehmer auf die UID2-Tokens zugreifen
							können.
						</li>
						<li>
							Betreiber-Dienste: Die Betreiber generieren und verwalten die UID2-Tokens. Unter Verwendung
							der Verschlüsselungsschlüssel und Salt Buckets des Verwaltungsdienstes übersetzen die
							Betreiber die vom Benutzer bereitgestellten Daten (E-Mail oder Telefonnummer) in sichere
							UID2-Tokens, die verwendet werden können, um eine bestimmte Anzahl von
							Adressierbarkeitsergebnissen zu ermöglichen.
						</li>
						<li>
							Nutzergesteuerte Dienste und technische Verantwortlichkeit: Es gibt auch APIs, die den
							Nutzern Transparenz und Kontrolle ermöglichen, indem sie der Verwaltung und den Betreibern
							auf überprüfbare Weise ihre Präferenzen mitteilen.
						</li>
					</ol>
					<p>
						Sie können Ihre allenfalls erteilte Einwilligung in eine Datenbearbeitung jederzeit mit Wirkung
						für die Zukunft hier durch Eingabe der Email-Adresse widerrufen:
						{' '}
						<a
							href='https://lohncheck.ch/de/daten-loeschen'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://lohncheck.ch/de/daten-loeschen
						</a>{' '}

					</p>
					<p>
						<i>Weitere Zwecke</i>
					</p>
					<p>
						Schliesslich können wir Ihre Personendaten aufgrund unserer berechtigten Interessen (Art. 6 Abs.
						1 lit. f DSGVO) zu folgenden Zwecken bearbeiten:
					</p>
					<ul>
						<li>
							<u>Optimierung:</u> Wir verwenden Ihre Personendaten zu analytischen Zwecken, um die
							Qualität unserer Dienstleistungen (insbesondere unser statistisches Modell zur
							Lohnberechnung) sowie die Nutzerfreundlichkeit und Funktionalitäten unserer Webseite zu
							verbessern. Falls wir Ihnen Angebote Dritter anzeigen können wir nachverfolgen, wie Sie die
							entsprechenden Links verwenden, um unsere Dienstleistungen zu verbessern und Statistiken zu
							erstellen.
						</li>
						<li>
							<u>Rechtsdurchsetzung:</u> Wir verwenden Ihre Personendaten gegebenenfalls zur
							Geltendmachung rechtlicher Ansprüche und Verteidigung in Zusammenhang mit rechtlichen
							Streitigkeiten und behördlichen Verfahren.
						</li>
						<li>
							<u>Verhinderung von Straftaten:</u> Wir verwenden Ihre Personendaten gegebenenfalls zur
							Verhinderung und Aufklärung von Straftaten und sonstigem Fehlverhalten (z.B. Datenanalysen
							zur Betrugsbekämpfung).
						</li>
						<li>
							<u>Verfügbarkeit und Sicherheit:</u> Wir verwenden
							Ihre Personendaten gegebenenfalls zur Gewährleistung
							der Verfügbarkeit und Sicherheit unserer Webseite.
						</li>
						<li>
							<u>Unternehmenstransaktionen:</u> Wir verwenden Ihre Personendaten gegebenenfalls zur
							Vorbereitung und Abwicklung von Unternehmenstransaktionen.
						</li>
					</ul>
					<p>
						Eine Datenbearbeitung kann auch zur Einhaltung rechtlicher Vorgaben erfolgen. Dabei können
						wir Daten auch an Behörden bekanntgeben.
					</p>
					<h2>4. Analyse Tools</h2>
					<p>
						<i>Cookies</i>
					</p>
					<p>
						Wir setzen auf unserer Webseite Cookies ein, um Sie bzw. Ihr Endgerät (Computer, Handy, Tablet
						etc.) bei einem erneuten Aufruf der Webseite (anhand einer in Ihrem Browser hinterlegten Codes)
						wiedererkennen zu können und damit wir Ihnen ein einwandfreies Surferlebnis gewährleisten
						können, besser verstehen, wie Sie unsere Webseite nutzen und personalisierte Angebote anzeigen
						können.
					</p>
					<p>
						Auf unserer Webseite können wir Elemente und Dienste Dritter (z.B. Stelleninserate, Vorsorge und
						Versicherungsprodukte) einsetzen, die uns Nutzungsstatistiken liefern, die zur Anzeige von
						Werbung dienen oder die Ihnen Zugang zu sozialen Netzwerken und anderen Fremdangeboten
						ermöglichen. Dadurch können diese Dritten in beschränktem Mass selbst Personendaten von Ihnen
						erheben, soweit sie Sie bzw. Ihr Endgerät z.B. anhand eigener Cookies oder Logins etc.
						wiedererkennen. Falls Sie jedoch auf den Link eines Fremdangebots klicken, sind wir für die
						weiteren Bearbeitungen Ihrer Personendaten nicht verantwortlich, d.h. Sie müssen sich an den
						Dritten halten. Wir können die Informationen zu Ihrer Nutzung unserer Webseite an unsere Partner
						für soziale Medien, Werbung und Analysen weitergeben, welche diese Informationen möglicherweise
						mit weiteren Daten zusammenführen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen
						Ihrer Nutzung der Dienste gesammelt haben. Schliesslich können wir unseren Newsletter und
						sonstigen Marketing-E-Mails mit persönlichen Links, damit wir besser verstehen, wie Sie unsere
						Angebote nutzen und unsere Cookies aktuell zu halten.
					</p>
					<p>
						Wir setzen Cookies und ähnliche Technologien zu Analyse- und Marketingzwecken nur ein, wenn Sie
						entsprechende Einstellungen in unserem Cookie-Banner aktiv bestätigen.
					</p>
					<p>
						Sie haben jederzeit die Möglichkeit, Ihren Browser bzw. Ihr E-Mail-Programm so zu konfigurieren,
						dass Cookies abgelehnt, nur für eine Sitzung gespeichert oder vorzeitig gelöscht werden. Falls
						Sie Cookies sperren, kann es jedoch sein, dass sich gewisse Funktionalitäten nicht nutzen
						lassen.
					</p>
					<p>
						<i>Facebook Custom Audience mit E-Mail-Adressen</i>
					</p>
					<p>
						Bei Facebook Custom Audiences handelt es sich um eine Remarketing-Kampagne, bei der der
						Werbetreibende die zu diesem Zweck erhobenen und gesammelten E-Mail-Adressen seiner Kunden mit
						Hilfe eines Algorithmus verschlüsselt und diese nicht-personenbezogene Prüfsumme (Hashwerte) auf
						den Facebook-Server hoch lädt. Facebook kann diese übermittelten Daten mit seiner Bestandsliste
						von verschlüsselten User-IDs abgleichen und die Übereinstimmungen im Kunden-Account des
						Werbenden als „Customer Audiences“ (= benutzerdefinierte Zielgruppe) abspeichern und
						zielgerichtet bewerben. Am Ende des Abgleichs werden alle hochgeladenen Hashwerte wieder
						gelöscht.
					</p>
					<p>
						Weitere Auskünfte über den Umfang der Erhebung und die weitere Verarbeitung und Nutzung der
						Daten durch Facebook Custom Audiences können Sie den Datenschutzrichtlinien von Facebook
						entnehmen, welche u.a. unter unter{' '}
						<a
							href='https://www.facebook.com/privacy/explanation'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.facebook.com/privacy/explanation
						</a>{' '}
						aufgeführt sind. Die Bedingungen für Custom Audiences finden Sie unter{' '}
						<a
							href='https://www.facebook.com/ads/manage/customaudiences/tos.php'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.facebook.com/ads/manage/customaudiences/tos.php
						</a>
						. Unter folgendem Link können Sie von der Sammlung und Verwendung von Informationen für an
						bestimmte Zielgruppen gerichtete Online-Werbung zurücktreten:{' '}
						<a
							href='https://www.facebook.com/ads/website_custom_audiences/'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.facebook.com/ads/website_custom_audiences/
						</a>
					</p>
					<p>
						<i>Google Custom Match mit E-Mail-Adressen</i>
					</p>
					<p>
						Bei Google Customer Match importieren Werbende in ihrem Google-AdWords-Account ihre eigenen
						Listen von Kunden-E-Mail-Adressen, die mit Hilfe eines Algorithmus verschlüsselt werden.
						Customer Match gleicht die anonymisierten E-Mail-Listen in AdWords mit der eigenen Datenbank der
						registrierten Nutzer ab und kann somit gezielt Anzeigen innerhalb der Google-Suche, YouTube und
						Gmail erstellen und Kampagnen selbstständig optimieren. Zusätzlich kann Google auf Basis der
						hochgeladenen Nutzerlisten auch ähnliche Kunden mit ähnlichen Interessen aufspüren.
					</p>
					<p>
						Mit der Funktion „Kundenabgleich“ schafft Google eine zentrale Informationsquelle für das
						Retargeting von Kunden (d. h. Besucher werden auf einer Webseite erfasst und auf anderen
						Webseiten mit gezielter Werbung erneut angesprochen). Für die vom Werbenden zur Verfügung
						gestellten Daten gelten die Richtlinien zur Funktion „Kundenabgleich“::{' '}
						<a
							href='https://support.google.com/adwordspolicy/answer/6299717?hl=de'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://support.google.com/adwordspolicy/answer/6299717?hl=de
						</a>
						.
					</p>
					<p>
						Weiterführende Informationen entnehmen Sie bitte der
						Datenschutzerklärung von Google unter:{' '}
						<a
							href='https://www.google.com/intl/de/policies/privacy/'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://www.google.com/intl/de/policies/privacy/
						</a>
						. Die Einstellungen zu persönlichen Daten und
						Privatsphäre können Sie unter Google „Mein Konto“
						bearbeiten:{' '}
						<a
							href='https://myaccount.google.com/intro?hl=de'
							target='_blank'
							rel='noopener noreferrer'
						>
							https://myaccount.google.com/intro?hl=de
						</a>
						.
					</p>
					<p>
						<i>The Trade Desk</i>
					</p>
					<p>
						Mit The Trade Desk nutzen wir eine Plattform, die es ermöglicht, Daten mit führenden ID-Lösungen
						wie Unified ID 2.0 oder EUID (encrypted User ID) abzugleichen. Durch diese Abgleichlösungen
						können Zielgruppen geräteübergreifend synchronisiert werden, wodurch die Reichweite auf
						verschiedenen Mediengattungen (Mobilgerät, Stationäre Geräte, TV) maximiert wird.
					</p>
					<h2>5. Bekanntgabe an weitere Empfänger</h2>
					<p>
						<i>Dienstleister</i>
					</p>
					<p>
						Für die Bearbeitung Ihrer Personendaten gemäss Ziffer 3 und 4 können wir Dienstleister
						(sogenannte „Auftragsbearbeiter“) beiziehen. Es handelt sich dabei namentlich um
						IT-Dienstleister, Statistiker für die Modellbildungen (z.B. Lohnrechner), Ersteller von
						Nutzungsstatistiken sowie Marketing- bzw. Newsletter-Dienstleister. Diese Dienstleister
						bearbeiten Ihre Personendaten in unserem Auftrag und ausschliesslich so, wie wir es selbst tun
						dürfen. Sie werden von uns in Bezug auf die Einhaltung der Datensicherheit geprüft und zur
						Geheimhaltung verpflichtet.
					</p>
					<p>
						<i>Dritte</i>
					</p>
					<p>
						Wir können Ihre Personendaten auch weiteren Dritten bekanntgeben. Wir bitten Sie dabei um Ihre
						gesonderte Einwilligung, soweit diese rechtlich erforderlich ist.
					</p>
					<p>
						Dabei kann es sich einerseits um Publisher (z.B. Online-Verlagshäuser) oder unseren
						Werbetechnologie-Partner wie Facebook und Google (vgl. Ziff. 4) handeln, welche die Daten
						entweder für uns (für den Fall, dass wir die Werbekampagnen ausspielen) oder aber auch zu
						eigenen Zwecken und damit in eigener Verantwortung bearbeiten (für den Fall, dass diese die
						Werbekampagnen selbst ausspielen). Bei den Personendaten, welche wir unseren
						Werbetechnologie-Partnern bekannt geben, handelt es sich um Ihre „gehashte“ (das heisst
						pseudonymisierte) E-Mail-Adresse und die weiteren gemachten Angaben, damit der
						Werbetechnologie-Partner Sie nur mittels Abgleiches mit anderweitig erhaltenen Hashwerten (d.h.
						falls Sie bei diesem Partner bereits über ein Login verfügen, z.B. Google-Account)
						wiedererkennen und die jeweilige Werbekampagne – gestützt auf die von Ihnen gemachten Angaben –
						personalisiert, d.h. zielgerichtet ausspielen kann.
					</p>
					<p>
						In Fällen, in denen wir Ihre Daten an unsere Partner wie beispielsweise Publisher zur
						Bearbeitung zu eigenen Zwecken weitergeben, sind diese für die weitere Bearbeitung Ihrer Daten
						selbst verantwortlich. Informationen zu ihrer Datenbearbeitung finden Sie in der entsprechenden
						Datenschutzerklärung unserer Partner. Für die Erhebung der Daten können wir und unsere Partner
						auch gemeinsam verantwortlich sein. In diesen Fällen treffen wir eine entsprechende
						Vereinbarung, über die Sie sich bei der unter Ziff. 1 angegebenen Adresse informieren können.
					</p>
					<h2>6. Bekanntgabe ins Ausland</h2>
					<p>
						Die in Ziffer 5 genannten Empfänger Ihrer Personendaten befinden sich in der Regel im
						EU-/EWR-Raum, können aber grundsätzlich auf der ganzen Welt niedergelassen sein.
					</p>
					<p>
						Wenn wir Ihre Personendaten an einen Empfänger in einen Land ohne angemessenen gesetzlichen
						Datenschutz übermitteln, stellen wir durch entsprechende Verträge (EU-Standardvertragsklauseln
						der Europäischen Union, die{' '}<a
						href='https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914'
						target='_blank'
						rel='noopener noreferrer'
					>
						hier
					</a>{' '}abrufbar sind und welche der EDÖB vorgängig genehmigt,
						ausgestellt oder anerkannt hat) einen angemessenen Datenschutz sicher oder stützen uns auf die
						gesetzlichen Ausnahmentatbestände der Einwilligung, der Vertragsabwicklung, der Feststellung,
						Ausübung oder Durchsetzung von Rechtsansprüchen, der überwiegenden öffentlichen Interessen, der
						veröffentlichten Personendaten oder der Notwendigkeit zum Schutze Ihrer Unversehrtheit. Sie
						können jederzeit eine Kopie der hier genannten vertraglichen Garantien bei uns beziehen. Wir
						behalten uns aber vor, Kopien aus datenschutzrechtlichen Gründen oder Gründen der Geheimhaltung
						zu schwärzen oder nur auszugsweise zu liefern.
					</p>
					<h2>7. Dauer der Aufbewahrung von Personendaten</h2>
					<p>
						Wir bearbeiten Ihre Personendaten, solange es für die Erfüllung unserer vertraglichen und/oder
						gesetzlichen Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist,
						vorbehalten bleiben allfällige gesetzliche Aufbewahrungs- und Dokumentationspflichten. Dabei ist
						es möglich, dass Personendaten für die Zeit aufbewahrt werden, in der Ansprüche gegen unser
						Unternehmen geltend gemacht werden können und soweit wir anderweitig gesetzlich dazu
						verpflichtet sind oder berechtigte Geschäftsinteressen dies erfordern (z.B. für Beweis- und
						Dokumentationszwecke). Sobald Ihre Personendaten für die oben genannten Zwecke nicht mehr
						erforderlich sind, werden sie grundsätzlich und soweit möglich gelöscht oder anonymisiert.
					</p>
					<h2>8. Datensicherheit</h2>
					<p>
						Wir treffen angemessene technische und organisatorische Massnahmen zum Schutz Ihrer
						Personendaten vor Verlust, unberechtigtem Zugriff und Missbrauch (bspw. durch interne Weisungen,
						Schulungen, IT- und Netzwerksicherheitslösungen, Zugangskontrollen und -beschränkungen,
						Verschlüsselung von Datenträgern und Übermittlungen, Pseudonymisierung von Personendaten etc.).
					</p>
					<h2>9. Ihre Rechte</h2>
					<p>
						Sie haben das Recht auf Auskunft, Berichtigung, Löschung sowie auf Herausgabe gewisser
						Personendaten zwecks Übertragung an eine andere Stelle. Soweit wir Daten auf Basis Ihrer
						Einwilligung bearbeiten, können Sie die Einwilligung jederzeit widerrufen. Der Widerruf gilt nur
						für die Zukunft, und wir behalten uns vor, Daten im Fall eines Widerrufs gestützt auf eine
						andere Grundlage weiterhin zu bearbeiten. Im Anwendungsbereich der DSGVO können Sie zudem die
						Einschränkung der Datenbearbeitung verlangen, wenn Sie die Richtigkeit der Daten bestreiten, die
						Verarbeitung unrechtmässig ist, die Daten für die Zwecke der Datenbearbeitung nicht mehr
						benötigt werden oder Sie Widerspruch gegen die Datenbearbeitung eingelegt haben. Ebenfalls haben
						Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
						Verarbeitung der Sie betreffenden Personendaten auf Grundlage von Artikel 6 Abs. 1 lit. e oder f
						(berechtigte Interessen), Widerspruch einzulegen. Sie können auch jederzeit der Bearbeitung
						Ihrer Personendaten widersprechen, die zum Zweck der Direktwerbung bearbeitet werden.
					</p>
					<p>
						Bitte beachten Sie aber bei der Geltendmachung Ihrer Rechte, dass wir uns vorbehalten,
						unsererseits die gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn wir zur
						Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet sind, daran ein überwiegendes
						Interesse haben oder sie für die Geltendmachung von Ansprüchen benötigen. Falls für Sie Kosten
						anfallen, werden wir Sie vorab informieren. Beachten Sie weiter, dass die Ausübung dieser Rechte
						im Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen wie z.B. die vorzeitige
						Vertragsauflösung oder Kosten haben kann. Wir werden Sie diesfalls vorgängig informieren, wo
						dies nicht bereits vertraglich geregelt ist. Die Ausübung solcher Rechte setzt in der Regel
						voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie). Zur
						Geltendmachung Ihrer Rechte können Sie uns unter der in Ziffer 1 angegebenen Adresse
						kontaktieren.
					</p>
					<p>
						Sie haben das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen
						Datenschutzbehörde Beschwerde einzureichen. Die zuständige Datenschutzbehörde der Schweiz ist
						der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte ({' '}<a
						href='https://www.edoeb.admin.ch'
						target='_blank'
						rel='noopener noreferrer'
					>
						https://www.edoeb.admin.ch
					</a>{' '}. Im Anwendungsbereich der DSGVO können Sie sich an die zuständigen nationalen
						Aufsichtsbehörden wenden (<a
						href='https://edpb.europa.eu/about-edpb/about-edpb/members_de'
						target='_blank'
						rel='noopener noreferrer'
					>
						https://edpb.europa.eu/about-edpb/about-edpb/members_de
					</a>{' '}).
					</p>
					<h2>10. Änderungen</h2>
					<p>
						Wir können diese Datenschutzerklärung bei Bedarf jederzeit ohne Vorankündigung anpassen. Es gilt
						die jeweils aktuelle, auf unserer Webseite publizierte Fassung.
					</p>
					<p>Version vom 01.02.2024</p>
				</>
			)
		}
	</Translate>
)

export default Privacy
