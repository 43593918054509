const countries = [
	{
		title: {
			de: 'Ägypten',
			en: 'Egypt',
			fr: 'Egypte',
			it: 'Egitto',
			es: 'Egipto',
		},
		value: 65,
		code: 'EG',
	},
	{
		title: {
			de: 'Albanien',
			en: 'Albania',
			fr: 'Albanie',
			it: 'Albania',
			es: 'Albania',
		},
		value: 2,
		code: 'AL',
	},
	{
		title: {
			de: 'Angola',
			en: 'Angola',
			fr: 'Angola',
			it: 'Angola',
			es: 'Angola',
		},
		value: 6,
		code: 'AO',
	},
	{
		title: {
			de: 'Argentinien',
			en: 'Argentina',
			fr: 'Argentine',
			it: 'Argentina',
			es: 'Argentina',
		},
		value: 10,
		code: 'AR',
	},
	{
		title: {
			de: 'Armenien',
			en: 'Armenia',
			fr: 'Arménie',
			it: 'Armenia',
			es: 'Armenia',
		},
		value: 11,
		code: 'AM',
	},
	{
		title: {
			de: 'Aserbaidschan',
			en: 'Azerbaijan',
			fr: 'Azerbaïdjan',
			it: 'Azerbaijan',
			es: 'Azerbaiyán',
		},
		value: 15,
		code: 'AZ',
	},
	{
		title: {
			de: 'Äthiopien',
			en: 'Ethiopia',
			fr: 'Ethiopie',
			it: 'Etiopia',
			es: 'Etiopía',
		},
		value: 72,
		code: 'ET',
	},
	{
		title: {
			de: 'Australien',
			en: 'Australia',
			fr: 'Australie',
			it: 'Australia',
			es: 'Australia',
		},
		value: 13,
		code: 'AU',
	},
	{
		title: {
			de: 'Bangladesch',
			en: 'Bangladesh',
			fr: 'Bangladesh',
			it: 'Bangladesh',
			es: 'Bangladés',
		},
		value: 18,
		code: 'BD',
	},
	{
		title: {
			de: 'Weissrussland',
			en: 'Belarus',
			fr: 'Bélarus',
			it: 'Bielorussia',
			es: 'Bielorrusia',
		},
		value: 20,
		code: 'BY',
	},
	{
		title: {
			de: 'Belgien',
			en: 'Belgium',
			fr: 'Belgique',
			it: 'Belgio',
			es: 'Bélgica',
		},
		value: 21,
		code: 'BE',
	},
	{
		title: {
			de: 'Belize',
			en: 'Belize',
			fr: 'Belize',
			it: 'Belize',
			es: 'Belice',
		},
		value: 22,
		code: 'BZ',
	},
	{
		title: {
			de: 'Benin',
			en: 'Benin',
			fr: 'Bénin',
			it: 'Benin',
			es: 'Benín',
		},
		value: 23,
		code: 'BJ',
	},
	{
		title: {
			de: 'Bosnien',
			en: 'Bosnia',
			fr: 'Bosnie',
			it: 'Bosnia',
			es: 'Bosnia',
		},
		value: 28,
		code: 'BIH',
	},
	{
		title: {
			de: 'Brasilien',
			en: 'Brazil',
			fr: 'Brésil',
			it: 'Brasile',
			es: 'Brasil',
		},
		value: 31,
		code: 'BR',
	},
	{
		title: {
			de: 'Bulgarien',
			en: 'Bulgaria',
			fr: 'Bulgarie',
			it: 'Bulgaria',
			es: 'Bulgaria',
		},
		value: 34,
		code: 'BG',
	},
	{
		title: {
			de: 'China',
			en: 'China',
			fr: 'Chine',
			it: 'Cina',
			es: 'China',
		},
		value: 45,
		code: 'CN',
	},
	{
		title: {
			de: 'Elfenbeinküste',
			en: 'Ivory Coast',
			fr: "Côte d'Ivoire",
			it: "Costa d'Avorio",
			es: 'Costa de Marfil',
		},
		value: 59,
		code: 'CIV',
	},
	{
		title: {
			de: 'Dänemark',
			en: 'Denmark',
			fr: 'Danemark',
			it: 'Danimarca',
			es: 'Dinamarca',
		},
		value: 60,
		code: 'DK',
	},
	{
		title: {
			de: 'Deutschland',
			en: 'Germany',
			fr: 'Allemagne',
			it: 'Germania',
			es: 'Alemania',
		},
		value: 84,
		code: 'DE',
	},
	{
		title: {
			de: 'Dominikanische Republik',
			en: 'Dominican Republic',
			fr: 'République dominicaine',
			it: 'Repubblica Dominicana',
			es: 'República Dominicana',
		},
		value: 63,
		code: 'DOM',
	},
	{
		title: {
			de: 'Ecuador',
			en: 'Ecuador',
			fr: 'Equateur',
			it: 'Ecuador',
			es: 'Ecuador',
		},
		value: 64,
		code: 'EC',
	},
	{
		title: {
			de: 'Ehemaliges Jugoslawien',
			en: 'Former Yugoslavia',
			fr: 'Ex-Yougoslavie',
			it: 'Jugoslavia',
			es: 'Antigua Yugoslavia',
		},
		value: 66,
		code: 'YU',
	},
	{
		title: {
			de: 'Eritrea',
			en: 'Eritrea',
			fr: 'Erythrée',
			it: 'Eritrea',
			es: 'Eritrea',
		},
		value: 69,
		code: 'ER',
	},
	{
		title: {
			de: 'Estland',
			en: 'Estonia',
			fr: 'Estonie',
			it: 'Estonia',
			es: 'Estonia',
		},
		value: 70,
		code: 'EE',
	},
	{
		title: {
			de: 'Finnland',
			en: 'Finland',
			fr: 'Finlande',
			it: 'Finlandia',
			es: 'Finlandia',
		},
		value: 76,
		code: 'FI',
	},
	{
		title: {
			de: 'Frankreich',
			en: 'France',
			fr: 'France',
			it: 'Francia',
			es: 'Francia',
		},
		value: 77,
		code: 'FR',
	},
	{
		title: {
			de: 'Griechenland',
			en: 'Greece',
			fr: 'Grèce',
			it: 'Grecia',
			es: 'Grecia',
		},
		value: 87,
		code: 'GR',
	},
	{
		title: {
			de: 'Indien',
			en: 'India',
			fr: 'Inde',
			it: 'India',
			es: 'India',
		},
		value: 104,
		code: 'IN',
	},
	{
		title: {
			de: 'Indonesien',
			en: 'Indonesia',
			fr: 'Indonésie',
			it: 'Indonesia',
			es: 'Indonesia',
		},
		value: 105,
		code: 'ID',
	},
	{
		title: {
			de: 'Irak',
			en: 'Iraq',
			fr: 'Irak',
			it: 'Iraq',
			es: 'Irak',
		},
		value: 107,
		code: 'IQ',
	},
	{
		title: {
			de: 'Iran',
			en: 'Iran',
			fr: 'Iran',
			it: 'Iran',
			es: 'Irán',
		},
		value: 106,
		code: 'IR',
	},
	{
		title: {
			de: 'Irland',
			en: 'Ireland',
			fr: 'Irlande',
			it: 'Irlanda',
			es: 'Irlanda',
		},
		value: 108,
		code: 'IE',
	},
	{
		title: {
			de: 'Israel',
			en: 'Israel',
			fr: 'Israël',
			it: 'Israele',
			es: 'Israel',
		},
		value: 110,
		code: 'IL',
	},
	{
		title: {
			de: 'Italien',
			en: 'Italy',
			fr: 'Italie',
			it: 'Italia',
			es: 'Italia',
		},
		value: 111,
		code: 'IT',
	},
	{
		title: {
			de: 'Kamerun',
			en: 'Cameroon',
			fr: 'Cameroun',
			it: 'Camerun',
			es: 'Camerún',
		},
		value: 39,
		code: 'CM',
	},
	{
		title: {
			de: 'Kasachstan',
			en: 'Kazachstan',
			fr: 'Kazakhstan',
			it: 'Kazakistan',
			es: 'Kazajistán',
		},
		value: 116,
		code: 'KZ',
	},
	{
		title: {
			de: 'Kenia',
			en: 'Kenya',
			fr: 'Kenya',
			it: 'Kenya',
			es: 'Kenia',
		},
		value: 117,
		code: 'KE',
	},
	{
		title: {
			de: 'Kolumbien',
			en: 'Columbia',
			fr: 'Colombie',
			it: 'Colombia',
			es: 'Columbia',
		},
		value: 48,
		code: 'CO',
	},
	{
		title: {
			de: 'Kroatien',
			en: 'Croatia',
			fr: 'Croatie',
			it: 'Croazia',
			es: 'Croacia',
		},
		value: 54,
		code: 'HR',
	},
	{
		title: {
			de: 'Kuba',
			en: 'Cuba',
			fr: 'Cuba',
			it: 'Cuba',
			es: 'Cuba',
		},
		value: 55,
		code: 'CU',
	},
	{
		title: {
			de: 'Laos',
			en: 'Laos',
			fr: 'Laos',
			it: 'Laos',
			es: 'Laos',
		},
		value: 123,
		code: 'LA',
	},
	{
		title: {
			de: 'Lettland',
			en: 'Latvia',
			fr: 'Lettonie',
			it: 'Lettonia',
			es: 'Letonia',
		},
		value: 124,
		code: 'LV',
	},
	{
		title: {
			de: 'Liechtenstein',
			en: 'Liechtenstein',
			fr: 'Liechtenstein',
			it: 'Liechtenstein',
			es: 'Liechtenstein',
		},
		value: 129,
		code: 'LI',
	},
	{
		title: {
			de: 'Litauen',
			en: 'Lithuania',
			fr: 'Lituanie',
			it: 'Lituania',
			es: 'Lituania',
		},
		value: 130,
		code: 'LT',
	},
	{
		title: {
			de: 'Mongolei',
			en: 'Mongolia',
			fr: 'Mongolie',
			it: 'Mongolia',
			es: 'Mongolia',
		},
		value: 148,
		code: 'MN',
	},
	{
		title: {
			de: 'Montenegro',
			en: 'Montenegro',
			fr: 'Monténégro',
			it: 'Montenegro',
			es: 'Montenegro',
		},
		value: 149,
		code: 'ME',
	},
	{
		title: {
			de: 'Niederlande',
			en: 'Netherlands',
			fr: 'Pays-Bas',
			it: 'Paesi Bassi',
			es: 'Países Bajos',
		},
		value: 157,
		code: 'NL',
	},
	{
		title: {
			de: 'Nigeria',
			en: 'Nigeria',
			fr: 'Nigeria',
			it: 'Nigeria',
			es: 'Nigeria',
		},
		value: 162,
		code: 'NG',
	},
	{
		title: {
			de: 'Österreich',
			en: 'Austria',
			fr: 'Autriche',
			it: 'Austria',
			es: 'Austria',
		},
		value: 14,
		code: 'AT',
	},
	{
		title: {
			de: 'Pakistan',
			en: 'Pakistan',
			fr: 'Pakistan',
			it: 'Pakistan',
			es: 'Pakistán',
		},
		value: 168,
		code: 'PK',
	},
	{
		title: {
			de: 'Peru',
			en: 'Peru',
			fr: 'Pérou',
			it: 'Perù',
			es: 'Perú',
		},
		value: 174,
		code: 'PE',
	},
	{
		title: {
			de: 'Philippinen',
			en: 'Philippines',
			fr: 'Philippines',
			it: 'Filippine',
			es: 'Filipinas',
		},
		value: 175,
		code: 'PH',
	},
	{
		title: {
			de: 'Polen',
			en: 'Poland',
			fr: 'Pologne',
			it: 'Polonia',
			es: 'Polonia',
		},
		value: 177,
		code: 'PL',
	},
	{
		title: {
			de: 'Portugal',
			en: 'Portugal',
			fr: 'Portugal',
			it: 'Portogallo',
			es: 'Portugal',
		},
		value: 178,
		code: 'PT',
	},
	{
		title: {
			de: 'Rumänien',
			en: 'Rumania',
			fr: 'Roumanie',
			it: 'Romania',
			es: 'Rumanía',
		},
		value: 182,
		code: 'RO',
	},
	{
		title: {
			de: 'Russland',
			en: 'Russia',
			fr: 'Russie',
			it: 'Russia',
			es: 'Rusia',
		},
		value: 183,
		code: 'RU',
	},
	{
		title: {
			de: 'Schweden',
			en: 'Sweden',
			fr: 'Suède',
			it: 'Svezia',
			es: 'Suecia',
		},
		value: 215,
		code: 'SE',
	},
	{
		title: {
			de: 'Schweiz',
			en: 'Switzerland',
			fr: 'Suisse',
			it: 'Svizzera',
			es: 'Suiza',
		},
		value: 216,
		code: 'CH',
	},
	{
		title: {
			de: 'Serbien',
			en: 'Serbia',
			fr: 'Serbie',
			it: 'Serbia',
			es: 'Serbia',
		},
		value: 198,
		code: 'RS',
	},
	{
		title: {
			de: 'Singapur',
			en: 'Singapore',
			fr: 'Singapour',
			it: 'Singapore',
			es: 'Singapur',
		},
		value: 201,
		code: 'SG',
	},
	{
		title: {
			de: 'Slowakei',
			en: 'Slovakia',
			fr: 'Slovaquie',
			it: 'Slovacchia',
			es: 'Eslovaquia',
		},
		value: 203,
		code: 'SK',
	},
	{
		title: {
			de: 'Slowenien',
			en: 'Slovinia',
			fr: 'Slovénie',
			it: 'Slovenia',
			es: 'Eslovenia',
		},
		value: 204,
		code: 'SI',
	},
	{
		title: {
			de: 'Spanien',
			en: 'Spain',
			fr: 'Espagne',
			it: 'Spagna',
			es: 'España',
		},
		value: 210,
		code: 'ES',
	},
	{
		title: {
			de: 'Sri Lanka',
			en: 'Sri Lanka',
			fr: 'Sri Lanka',
			it: 'Sri Lanka',
			es: 'Sri Lanka',
		},
		value: 211,
		code: 'LK',
	},
	{
		title: {
			de: 'Thailand',
			en: 'Thailand',
			fr: 'Thaïlande',
			it: 'Tailandia',
			es: 'Tailandia',
		},
		value: 221,
		code: 'TH',
	},
	{
		title: {
			de: 'Trinidad und Tobago',
			en: 'Trinidad and Tobago',
			fr: 'Trinité-et-Tobago',
			it: 'Trinidad e Tobago',
			es: 'Trinidad y Tobago',
		},
		value: 226,
		code: 'TT',
	},
	{
		title: {
			de: 'Tschechische Republik',
			en: 'Czech Republic',
			fr: 'République tchèque',
			it: 'Repubblica Ceca',
			es: 'República Checa',
		},
		value: 58,
		code: 'CZE',
	},
	{
		title: {
			de: 'Tunesien',
			en: 'Tunisia',
			fr: 'Tunisie',
			it: 'Tunisia',
			es: 'Túnez',
		},
		value: 227,
		code: 'TN',
	},
	{
		title: {
			de: 'Türkei',
			en: 'Turkey',
			fr: 'Turquie',
			it: 'Turchia',
			es: 'Turquía',
		},
		value: 228,
		code: 'TR',
	},
	{
		title: {
			de: 'Ukraine',
			en: 'Ukraine',
			fr: 'Ukraine',
			it: 'Ucraina',
			es: 'Ucrania',
		},
		value: 233,
		code: 'UA',
	},
	{
		title: {
			de: 'Ungarn',
			en: 'Hungary',
			fr: 'Hongrie',
			it: 'Ungheria',
			es: 'Hungría',
		},
		value: 102,
		code: 'HU',
	},
	{
		title: {
			de: 'Uruguay',
			en: 'Uruguay',
			fr: 'Uruguay',
			it: 'Uruguay',
			es: 'Uruguay',
		},
		value: 238,
		code: 'UY',
	},
	{
		title: {
			de: 'Usbekistan',
			en: 'Uzbekistan',
			fr: 'Ouzbékistan',
			it: 'Uzbekistan',
			es: 'Uzbekistán',
		},
		value: 239,
		code: 'UZ',
	},
	{
		title: {
			de: 'Vereinigte Staaten',
			en: 'United States',
			fr: 'Etats-Unis',
			it: 'Stati Uniti',
			es: 'Estados Unidos',
		},
		value: 237,
		code: 'US',
	},
	{
		title: {
			de: 'Vereinigtes Königreich',
			en: 'United Kingdom',
			fr: 'Royaume-Uni',
			it: 'Regno Unito',
			es: 'Reino Unido',
		},
		value: 235,
		code: 'GB',
	},
	{
		title: {
			de: 'Vietnam',
			en: 'Vietnam',
			fr: 'Viêt Nam',
			it: 'Vietnam',
			es: 'Vietnam',
		},
		value: 242,
		code: 'VN',
	},
	{
		title: {
			de: 'Keine Angabe',
			en: 'Not specified',
			fr: 'Non spécifié',
			it: 'Non specificato',
			es: 'Sin especificar',
		},
		value: null,
		code: null,
	},
]

const cantons = [
	{
		title: {
			de: 'Aargau',
			en: 'Argovia',
			fr: 'Argovie',
			it: 'Argovia',
			es: 'Argovia',
		},
		value: 1,
	},
	{
		title: {
			de: 'Appenzell AR',
			en: 'Appenzell AR',
			fr: 'Appenzell Rhodes-Extérieures',
			it: 'Appenzello Esterno',
			es: 'Appenzell Rodas Exteriores',
		},
		value: 2,
	},
	{
		title: {
			de: 'Appenzell IR',
			en: 'Appenzell IR',
			fr: 'Appenzell Rhodes-Intérieures',
			it: 'Appenzello Interno',
			es: 'Appenzell Rodas Interiores',
		},
		value: 3,
	},
	{
		title: {
			de: 'Basel Land',
			en: 'Suburbs of Basel',
			fr: 'Bâle-Campagne',
			it: 'Sobborghi di Basilea',
			es: 'Suburbios de Basilea',
		},
		value: 4,
	},
	{
		title: {
			de: 'Basel Stadt',
			en: 'City of Basel',
			fr: 'Bâle-Ville',
			it: 'Città di Basilea',
			es: 'Ciudad de Basilea',
		},
		value: 5,
	},
	{
		title: {
			de: 'Bern Land',
			en: 'suburbs of Berne',
			fr: 'Berne-Campagne',
			it: 'Sobborghi di Berna',
			es: 'suburbios de Berna',
		},
		value: 6,
	},
	{
		title: {
			de: 'Bern Stadt',
			en: 'City of Berne',
			fr: 'Berne-Ville',
			it: 'Sobborghi di Berna',
			es: 'Ciudad de Berna',
		},
		value: 7,
	},
	{
		title: {
			de: 'Freiburg',
			en: 'Freiburg',
			fr: 'Fribourg',
			it: 'Friburgo',
			es: 'Freiburg',
		},
		value: 8,
	},
	{
		title: {
			de: 'Genf',
			en: 'Geneva',
			fr: 'Genève',
			it: 'Ginevra',
			es: 'Ginebra',
		},
		value: 9,
	},
	{
		title: {
			de: 'Glarus',
			en: 'Glarus',
			fr: 'Glaris',
			it: 'Glarona',
			es: 'Glaris',
		},
		value: 10,
	},
	{
		title: {
			de: 'Graubünden',
			en: 'Grisons',
			fr: 'Grisons',
			it: 'Grigioni',
			es: 'Grisones',
		},
		value: 11,
	},
	{
		title: {
			de: 'Jura',
			en: 'Jura',
			fr: 'Jura',
			it: 'Giura',
			es: 'Jura',
		},
		value: 12,
	},
	{
		title: {
			de: 'Luzern',
			en: 'Lucerne',
			fr: 'Lucerne',
			it: 'Lucerna',
			es: 'Lucerna',
		},
		value: 13,
	},
	{
		title: {
			de: 'Neuenburg',
			en: 'Neuenburg',
			fr: 'Neuchâtel',
			it: 'Neuchâtel',
			es: 'Neuenburg',
		},
		value: 14,
	},
	{
		title: {
			de: 'Nidwalden',
			en: 'Nidwald',
			fr: 'Nidwald',
			it: 'Nidvaldo',
			es: 'Nidwalden',
		},
		value: 15,
	},
	{
		title: {
			de: 'Obwalden',
			en: 'Obwalden',
			fr: 'Obwald',
			it: 'Obvaldo',
			es: 'Obwalden',
		},
		value: 16,
	},
	{
		title: {
			de: 'Schaffhausen',
			en: 'Schaffhausen',
			fr: 'Schaffhouse',
			it: 'Sciaffusa',
			es: 'Schaffhausen',
		},
		value: 17,
	},
	{
		title: {
			de: 'Schwyz',
			en: 'Schwyz',
			fr: 'Schwytz',
			it: 'Svitto',
			es: 'Schwyz',
		},
		value: 18,
	},
	{
		title: {
			de: 'Solothurn',
			en: 'Solothurn',
			fr: 'Soleure',
			it: 'Soletta',
			es: 'Soleura',
		},
		value: 19,
	},
	{
		title: {
			de: 'St. Gallen Land',
			en: 'Suburbs of St. Gallen',
			fr: 'Saint-Gall-Campagne',
			it: 'Sobborghi di San Gallo',
			es: 'Suburbios de San Galo',
		},
		value: 20,
	},
	{
		title: {
			de: 'St. Gallen Stadt',
			en: 'City of St. Gallen',
			fr: 'Saint-Gall-Ville',
			it: 'Città di San Gallo',
			es: 'Ciudad de San Galo',
		},
		value: 21,
	},
	{
		title: {
			de: 'Tessin',
			en: 'Ticino',
			fr: 'Tessin',
			it: 'Ticino',
			es: 'Tesino',
		},
		value: 22,
	},
	{
		title: {
			de: 'Thurgau',
			en: 'Thurgau',
			fr: 'Thurgovie',
			it: 'Turgovia',
			es: 'Turgovia',
		},
		value: 23,
	},
	{
		title: {
			de: 'Uri',
			en: 'Uri',
			fr: 'Uri',
			it: 'Uri',
			es: 'Uri',
		},
		value: 24,
	},
	{
		title: {
			de: 'Waadt',
			en: 'Waadt',
			fr: 'Vaud',
			it: 'Vaud',
			es: 'Vaud',
		},
		value: 25,
	},
	{
		title: {
			de: 'Wallis',
			en: 'Valais',
			fr: 'Valais',
			it: 'Vallese',
			es: 'Valais',
		},
		value: 26,
	},
	{
		title: {
			de: 'Zürich Land',
			en: 'Suburbs of Zurich',
			fr: 'Zurich-Campagne',
			it: 'Sobborghi di Zurigo',
			es: 'Suburbios de Zúrich',
		},
		value: 27,
	},
	{
		title: {
			de: 'Zürich Stadt',
			en: 'City of Zurich',
			fr: 'Zurich-Ville',
			it: 'Città di Zurigo',
			es: 'Ciudad de Zúrich',
		},
		value: 28,
	},
	{
		title: {
			de: 'Zug',
			en: 'Zug',
			fr: 'Zoug',
			it: 'Zugo',
			es: 'Zug',
		},
		value: 29,
	},
	{
		title: {
			de: 'Liechtenstein',
			en: 'Liechtenstein',
			fr: 'Liechtenstein',
			it: 'Liechtenstein',
			es: 'Liechtenstein',
		},
		value: 30,
	},
]

const data = {
	// Step 1
	careerLevel: {
		id: 'career_level_id',
		label: {
			de: 'Karrierelevel',
			en: 'Occupational career level',
			fr: 'Niveau de carrière',
			it: 'Livello di carriera professionale',
			es: 'Nivel laboral profesional',
		},
		options: [
			{
				title: {
					de: 'Berufseinsteiger',
					en: 'Young professional',
					fr: 'Débutant',
					it: 'Giovane professionista',
					es: 'Profesional joven',
				},
				value: 1,
			},
			{
				title: {
					de: 'mit Berufserfahrung',
					en: 'With professional experience',
					fr: 'avec expérience professionnelle',
					it: 'Con esperienza professionale',
					es: 'Con experiencia profesional',
				},
				value: 2,
			},
			{
				title: {
					de: 'Spezialist',
					en: 'Specialist',
					fr: 'Spécialiste',
					it: 'Specialista',
					es: 'Especialista',
				},
				value: 3,
			},
			{
				title: {
					de: 'Führungskraft/Direktor/Geschäftsführer',
					en: 'Manager/Director/Executive',
					fr: 'Cadre supérieur/Directeur /PDG',
					it: 'Manager/Direttore/Esecutivo',
					es: 'Gerente/director/ejecutivo',
				},
				value: 4,
			},
		],
	},
	education: {
		id: 'education_id',
		label: {
			de: 'Höchste Ausbildung',
			en: 'Highest education',
			fr: 'Niveau de qualification',
			it: 'Massimo grado di istruzione',
			es: 'Máximo nivel educativo',
		},
		// tooltip: true,
		// tooltipText: {
		//     de: 'Hier folgt ein Text für einen Tooltip.',
		//     en: 'The words of a "tooltip" follow here',
		//     fr: 'The words of a "tooltip" follow here',
		//     it: 'Di seguito sono riportate le parole di un "tooltip"',
		//     es: 'Las palabras de una “herramienta” aparecerán aquí',
		// },
		options: [
			{
				title: {
					de: 'Anlehre',
					en: 'Training time',
					fr: 'Formation élémentaire',
					it: 'Durata della formazione',
					es: 'Tiempo de formación',
				},
				value: 1,
			},
			{
				title: {
					de: 'Berufslehre',
					en: 'Apprenticeship',
					fr: 'Apprentissage',
					it: 'Tirocinio',
					es: 'Aprendizaje',
				},
				value: 2,
			},
			{
				title: {
					de: 'KV Lehre/HS',
					en: 'Commercial apprenticeship/Fall term',
					fr: 'Apprentissage commercial/Etudes commerciales',
					it: 'Apprendistato commerciale/Periodo autunnale',
					es: 'Aprendizaje comercial/trimestre de otoño',
				},
				value: 3,
			},
			{
				title: {
					de: 'Fachausbildung',
					en: 'Professional training',
					fr: 'Formation spécialisée',
					it: 'Formazione professionale',
					es: 'Formación profesional',
				},
				value: 4,
			},
			{
				title: {
					de: 'Höhere Fachschule',
					en: 'Technical college',
					fr: 'Ecole supérieure',
					it: 'Istituto tecnico',
					es: 'Universidad técnica',
				},
				value: 5,
			},
			{
				title: {
					de: 'Uni-/Hochschulabschluss',
					en: 'University/Technical degree',
					fr: "Diplôme d'une haute école/universitaire",
					it: 'Diploma universitario/tecnico',
					es: 'Universidad',
				},
				value: 6,
			},
			{
				title: {
					de: 'MAS-Lehrgang',
					en: 'MAS course',
					fr: 'Formation MAS',
					it: 'Corso MAS',
					es: 'Curso DEA',
				},
				value: 7,
			},
			{
				title: {
					de: 'Fachhochschulabschluss',
					en: 'Polytechnic degree',
					fr: "Diplôme d'une haute école spécialisée",
					it: 'Diploma del politecnico',
					es: 'Grado tecnológico',
				},
				value: 8,
			},
		],
	},
	educationName: {
		id: 'education_name',
		label: {
			de: 'Genaue Bezeichnung Ausbildung',
			en: 'Exact description of education',
			fr: 'Désignation précise de la formation',
			it: 'Descrizione esatta della formazione',
			es: 'Descripción exacta de la educación',
		},
		placeholder: {
			de: 'z. B. Frontend Developer',
			en: 'e.g. Front end developer',
			fr: 'Exemple: développeur Front-End',
			it: 'ad es. sviluppatore front-end',
			es: 'ej. Desarrollador de Frontend',
		},
		optional: true,
	},
	university: {
		id: 'university',
		label: {
			de: 'Name der Schule / Universität',
			en: 'Name of the school/university',
			fr: "Nom de l'école/université",
			it: "Nome dell'istituto/università",
			es: 'Nombre de la institución/la universidad',
		},
		placeholder: {
			de: 'z. B. ETH Zürich',
			en: 'e.g. Federal Institute of Technology in Zurich',
			fr: 'Exemple: EPFL Lausanne',
			it: 'ad es. Politecnico federale di Zurigo',
			es: 'e.g. Federal Institute of Technology de Zúrich',
		},
		optional: true,
	},
	searchingForOther: {
		id: 'searching_for_other',
		label: {
			de: 'Für wen suchen Sie den Lohn?',
			en: 'For whom are you seeking the wages',
			fr: 'Pour qui cherchez-vous un salaire?',
			it: 'Per chi stai calcolando il salario',
			es: 'Para quién busca los sueldos',
		},
		radios: {
			radio1: {
				id: 'forMe',
				label: {
					de: 'Für mich',
					en: 'For myself',
					fr: 'Pour moi',
					it: 'Per me',
					es: 'Para mí',
				},
				value: '0',
			},
			radio2: {
				id: 'forSomeoneElse',
				label: {
					de: 'Jemand anderen',
					en: 'For someone else',
					fr: "Pour quelqu'un d'autre",
					it: 'Per qualcun altro',
					es: 'Para otro',
				},
				value: '1',
			},
		},
	},

	// Step 2
	company: {
		id: 'company',
		label: {
			de: 'Firma',
			en: 'Firm',
			fr: 'Inébranlable',
			it: 'Solido',
			es: 'Firme',
		},
		optional: true,
		placeholder: {
			de: 'Ihr aktueller Arbeitgeber',
			en: 'Your current employer',
			fr: 'Votre employeur actuel',
			it: 'Il tuo attuale datore di lavoro',
			es: 'Su empleador actual',
		},
	},
	industry: {
		id: 'industry_id',
		label: {
			de: 'Branche',
			en: 'Industry',
			fr: 'Secteur',
			it: 'Settore',
			es: 'Sector',
		},
		options: [
			{
				title: {
					de: 'Autogewerbe',
					en: 'Automobile industry',
					fr: 'Industrie automobile',
					it: 'Settore automobilistico',
					es: 'Sector del automóvil',
				},
				value: 1,
			},
			{
				title: {
					de: 'Banken',
					en: 'Banking',
					fr: 'Banques',
					it: 'Bancario',
					es: 'Banca',
				},
				value: 2,
			},
			{
				title: {
					de: 'Bau',
					en: 'Construction',
					fr: 'Construction',
					it: 'Edile',
					es: 'Construcción',
				},
				value: 3,
			},
			{
				title: {
					de: 'Beratung/Revision',
					en: 'Consulting/Auditing',
					fr: 'Conseil/Audit',
					it: 'Consulenza/revisione contabile',
					es: 'Asesoría/auditoría',
				},
				value: 4,
			},
			{
				title: {
					de: 'Bund',
					en: 'Federal government',
					fr: 'Confédération',
					it: 'Governativo',
					es: 'Gobierno federal',
				},
				value: 5,
			},
			{
				title: {
					de: 'Chemie',
					en: 'Chemistry',
					fr: 'Chimie',
					it: 'Chimico',
					es: 'Química',
				},
				value: 6,
			},
			{
				title: {
					de: 'Detailhandel',
					en: 'Retail trade',
					fr: 'Commerce de détail',
					it: 'Vendita al dettaglio',
					es: 'Comercio minorista',
				},
				value: 7,
			},
			{
				title: {
					de: 'Druck und Grafik',
					en: 'Printing and graphics',
					fr: 'Imprimé et arts graphiques',
					it: 'Stampa e grafica',
					es: 'Imprenta y gráficos',
				},
				value: 8,
			},
			{
				title: {
					de: 'Elektroindustrie',
					en: 'Electronics industry',
					fr: 'Industrie électronique',
					it: "Settore dell'elettronica",
					es: 'Sector de la electrónica',
				},
				value: 9,
			},
			{
				title: {
					de: 'Gastronomie und Tourismus',
					en: 'Gastronomy and tourism',
					fr: 'Gastronomie et tourisme',
					it: 'Ristorazione e turismo',
					es: 'Gastronomía y turismo',
				},
				value: 10,
			},
			{
				title: {
					de: 'Gesundheitswesen',
					en: 'Health care sector',
					fr: 'Santé',
					it: 'Sanitario',
					es: 'Sector de la salud',
				},
				value: 11,
			},
			{
				title: {
					de: 'Informatik',
					en: 'Computer sciences',
					fr: 'Informatique',
					it: 'Informatica',
					es: 'Informática',
				},
				value: 12,
			},
			{
				title: {
					de: 'Kantone/Gemeinden',
					en: 'Cantons/communities',
					fr: 'Cantons/Communes',
					it: 'Cantoni/comunità',
					es: 'Cantones/comunidades',
				},
				value: 13,
			},
			{
				title: {
					de: 'Maschinen und Metallindustrie',
					en: 'Machinery and metals industry',
					fr: 'Industrie des machines et du métal',
					it: 'Industria meccanica e metallurgica',
					es: 'Sector de la maquinaria y los metales',
				},
				value: 14,
			},
			{
				title: {
					de: 'Medizinaltechnik',
					en: 'Medical technology',
					fr: 'Technologie médicale',
					it: 'Tecnologie mediche',
					es: 'Tecnología médica',
				},
				value: 15,
			},
			{
				title: {
					de: 'Militär',
					en: 'Military',
					fr: 'Armée',
					it: 'Militare',
					es: 'Ejército',
				},
				value: 16,
			},
			{
				title: {
					de: 'Nahrungsmittel',
					en: 'Food products',
					fr: 'Produits alimentaires',
					it: 'Prodotti alimentari',
					es: 'Productos alimenticios',
				},
				value: 17,
			},
			{
				title: {
					de: 'Pharma',
					en: 'Pharmaceuticals',
					fr: 'Industrie pharmaceutique',
					it: 'Farmaceutico',
					es: 'Farmacéutico',
				},
				value: 18,
			},
			{
				title: {
					de: 'Telecom',
					en: 'Telecommunications',
					fr: 'Télécommunications',
					it: 'Telecomunicazioni',
					es: 'Telecomunicaciones',
				},
				value: 19,
			},
			{
				title: {
					de: 'Textil',
					en: 'Textile industry',
					fr: 'Textile',
					it: 'Tessile',
					es: 'Sector textil',
				},
				value: 20,
			},
			{
				title: {
					de: 'Uhren/Feintechnik',
					en: 'Watches/precision technology',
					fr: 'Montres/Appareils de précision',
					it: 'Orologi/tecnologia di precisione',
					es: 'Relojes/tecnología de precisión',
				},
				value: 21,
			},
			{
				title: {
					de: 'Unterrichtswesen',
					en: 'Educational system',
					fr: 'Enseignement',
					it: 'Sistema formativo',
					es: 'Sistema educativo',
				},
				value: 22,
			},
			{
				title: {
					de: 'Verkehrsbetriebe',
					en: 'Transport services',
					fr: 'Transports',
					it: 'Servizi di trasporto',
					es: 'Servicios de transporte',
				},
				value: 23,
			},
			{
				title: {
					de: 'Versicherungen',
					en: 'Insurance industry',
					fr: 'Assurances',
					it: 'Assicurativo',
					es: 'Sector de los seguros',
				},
				value: 24,
			},
			{
				title: {
					de: 'Andere',
					en: 'Other',
					fr: 'Autre',
					it: 'Altro',
					es: 'Otro',
				},
				value: 25,
			},
		],
	},
	companySize: {
		id: 'company_size_id',
		label: {
			de: 'Anzahl Mitarbeiter in Ihrer Firma',
			en: 'Number of employees in your company',
			fr: 'Nombre de collaborateurs dans votre entreprise',
			it: 'Numero di dipendenti nella tua azienda',
			es: 'Número de empleados en su compañía',
		},
		options: [
			{
				title: {
					de: 'Einzelfirma',
					en: 'Single proprietorship',
					fr: 'Entreprise individuelle',
					it: 'Società individuale',
					es: 'Un propietario único',
				},
				value: 1,
			},
			{
				title: {
					de: '1-10 Mitarbeiter',
					en: '1-10 employees',
					fr: '1-10 collaborateurs',
					it: '1-10 dipendenti',
					es: '1-10 empleados',
				},
				value: 2,
			},
			{
				title: {
					de: '11-50 Mitarbeiter',
					en: '11-50 employees',
					fr: '11-50 collaborateurs',
					it: '11-50 dipendenti',
					es: '11-50 empleados',
				},
				value: 3,
			},
			{
				title: {
					de: '51-200 Mitarbeiter',
					en: '51-200 employees',
					fr: '51-200 collaborateurs',
					it: '51-200 dipendenti',
					es: '51-200 empleados',
				},
				value: 4,
			},
			{
				title: {
					de: '201-500 Mitarbeiter',
					en: '201-500 employees',
					fr: '201-500 collaborateurs',
					it: '201-500 dipendenti',
					es: '201-500 empleados',
				},
				value: 5,
			},
			{
				title: {
					de: '501-1000 Mitarbeiter',
					en: '501-1000 employees',
					fr: '501-1000 collaborateurs',
					it: '501-1000 dipendenti',
					es: '501-1000 empleados',
				},
				value: 6,
			},
			{
				title: {
					de: '1001-5000 Mitarbeiter',
					en: '1001-5000 employees',
					fr: '1001-5000 collaborateurs',
					it: '1001-5000 dipendenti',
					es: '1001-5000 empleados',
				},
				value: 7,
			},
			{
				title: {
					de: '5001-10000 Mitarbeiter',
					en: '5001-10000 employees',
					fr: '5001-10000 collaborateurs',
					it: '5001-10000 dipendenti',
					es: '5001-10000 empleados',
				},
				value: 8,
			},
			{
				title: {
					de: '10001 und mehr Mitarbeiter',
					en: 'more than 10000 employees',
					fr: 'Plus de 10000 collaborateurs',
					it: '10000+ dipendenti',
					es: 'más de 10000 empleados',
				},
				value: 9,
			},
		],
	},
	companyOrientation: {
		id: 'company_orientation_id',
		label: {
			de: 'Ausrichtung der Firma',
			en: 'Focus of the company',
			fr: "Orientation de l'entreprise",
			it: "Obiettivi dell'azienda",
			es: 'Enfoque de la empresa',
		},
		options: [
			{
				title: {
					de: 'nicht bestimmt',
					en: 'Not determined',
					fr: 'non déterminée',
					it: 'Non determinati',
					es: 'Sin determinar',
				},
				value: 1,
			},
			{
				title: {
					de: 'Regional',
					en: 'Regional',
					fr: 'Régionale',
					it: 'Regionali',
					es: 'Regional',
				},
				value: 2,
			},
			{
				title: {
					de: 'Überregional',
					en: 'Transregional',
					fr: 'Suprarégionale',
					it: 'Transregionali',
					es: 'Transregional',
				},
				value: 3,
			},
			{
				title: {
					de: 'National',
					en: 'National',
					fr: 'Nationale',
					it: 'Nazionali',
					es: 'Nacional',
				},
				value: 4,
			},
			{
				title: {
					de: 'International',
					en: 'International',
					fr: 'Internationale',
					it: 'Internazionali',
					es: 'Internacional',
				},
				value: 5,
			},
			{
				title: {
					de: 'Multinational',
					en: 'Multinational',
					fr: 'Multinationale',
					it: 'Multinazionali',
					es: 'Multinacional',
				},
				value: 6,
			},
		],
	},
	companyType: {
		id: 'company_type_id',
		label: {
			de: 'Gesellschaftsform',
			en: 'Corporate form',
			fr: 'Forme juridique de la société',
			it: 'Forma societaria',
			es: 'Forma corporativa',
		},
		options: [
			{
				title: {
					de: 'Einzelunternehmen',
					en: 'Sole proprietorship',
					fr: 'Entreprise individuelle',
					it: 'Società individuale',
					es: 'Un propietario',
				},
				value: 1,
			},
			{
				title: {
					de: 'Genossenschaft',
					en: 'Association',
					fr: 'Coopérative',
					it: 'Associazione',
					es: 'Asociación',
				},
				value: 2,
			},
			{
				title: {
					de: 'Kollektivgesellschaft',
					en: 'Collective proprietorship',
					fr: 'Société en nom collectif',
					it: 'Proprietà collettiva',
					es: 'Propiedad colectiva',
				},
				value: 3,
			},
			{
				title: {
					de: 'Gemeinnützig',
					en: 'Non-profit',
					fr: "Société d'utilité publique",
					it: 'No-profit',
					es: 'Sin ánimo de lucro',
				},
				value: 4,
			},
			{
				title: {
					de: 'GmbH',
					en: 'LLC',
					fr: 'S.A.R.L.',
					it: 'S.r.l.',
					es: 'Sociedad Limitada',
				},
				value: 5,
			},
			{
				title: {
					de: 'AG',
					en: 'Corp.',
					fr: 'SA',
					it: 'Spa',
					es: 'Corp.',
				},
				value: 6,
			},
			{
				title: {
					de: 'Staatlich',
					en: 'State-owned',
					fr: 'Entreprise publique',
					it: 'Statale',
					es: 'De propiedad estatal',
				},
				value: 7,
			},
		],
	},

	// Step 3
	jobCategory: {
		id: 'job_category_id',
		label: {
			de: 'Berufsgruppe',
			en: 'Occupational group',
			fr: 'Catégorie professionnelle',
			it: 'Categoria professionale',
			es: 'Grupo laboral',
		},
		options: [
			{
				title: {
					de: 'Architektur',
					en: 'Architecture',
					fr: 'Architecture',
					it: 'Architettura',
					es: 'Arquitectura',
				},
				value: 1,
			},
			{
				title: {
					de: 'Bau',
					en: 'Construction',
					fr: 'Construction',
					it: 'Edilizia',
					es: 'Construcción',
				},
				value: 2,
			},
			{
				title: {
					de: 'Finanzen',
					en: 'Finance',
					fr: 'Finances',
					it: 'Finanza',
					es: 'Financiero',
				},
				value: 3,
			},
			{
				title: {
					de: 'Gastgewerbe, Hotel',
					en: 'Hospitality industry, hotel',
					fr: 'Industrie hôtelière',
					it: 'Settore ricettivo, alberghiero',
					es: 'Sector de la hostelería, hotel',
				},
				value: 4,
			},
			{
				title: {
					de: 'Gesundheit und Pflege',
					en: 'Health and nursing',
					fr: 'Santé et soins',
					it: 'Sanità ed infermieristica',
					es: 'Salud y enfermería',
				},
				value: 5,
			},
			{
				title: {
					de: 'Gewerbe und Industrie',
					en: 'Business and industry',
					fr: 'Commerce et industrie',
					it: 'Imprese e settore industriale',
					es: 'Negocios e industria',
				},
				value: 6,
			},
			{
				title: {
					de: 'Informatik',
					en: 'Information technology',
					fr: 'Informatique',
					it: 'Informatica',
					es: 'Informática',
				},
				value: 7,
			},
			{
				title: {
					de: 'Kaufmännische Berufe',
					en: 'Commercial occupations',
					fr: 'Professions commerciales',
					it: 'Attività commerciali',
					es: 'Profesiones comerciales',
				},
				value: 8,
			},
			{
				title: {
					de: 'Marketing',
					en: 'Marketing',
					fr: 'Marketing',
					it: 'Marketing',
					es: 'Marketing',
				},
				value: 9,
			},
			{
				title: {
					de: 'Soziales',
					en: 'Social issues',
					fr: 'Social',
					it: 'Temi sociali',
					es: 'Temas sociales',
				},
				value: 10,
			},
			{
				title: {
					de: 'Technische Industrie',
					en: 'Technical industry',
					fr: 'Industrie technique',
					it: 'Industrie tecniche',
					es: 'Sector técnico',
				},
				value: 11,
			},
			{
				title: {
					de: 'Verkauf',
					en: 'Sales',
					fr: 'Vente',
					it: 'Vendite',
					es: 'Ventas',
				},
				value: 12,
			},
			{
				title: {
					de: 'Diverses',
					en: 'Miscellaneous',
					fr: 'Divers',
					it: 'Vario',
					es: 'Diversos',
				},
				value: 13,
			},
		],
	},
	workload: {
		id: 'workload_id',
		label: {
			de: 'Pensum',
			en: 'Workload',
			fr: "Taux d'occupation",
			it: 'Carico di lavoro',
			es: 'Volumen de trabajo',
		},
		options: [
			{
				title: {
					de: '20%',
					en: '20%',
					fr: '20%',
					it: '20%',
					es: '20%',
				},
				value: 2,
			},
			{
				title: {
					de: '40%',
					en: '40%',
					fr: '40%',
					it: '40%',
					es: '40%',
				},
				value: 4,
			},
			{
				title: {
					de: '60%',
					en: '60%',
					fr: '60%',
					it: '60%',
					es: '60%',
				},
				value: 6,
			},
			{
				title: {
					de: '80%',
					en: '80%',
					fr: '80%',
					it: '80%',
					es: '80%',
				},
				value: 8,
			},
			{
				title: {
					de: '90%',
					en: '90%',
					fr: '90%',
					it: '90%',
					es: '90%',
				},
				value: 9,
			},
			{
				title: {
					de: '100%',
					en: '100%',
					fr: '100%',
					it: '100%',
					es: '100%',
				},
				value: 10,
			},
		],
	},
	workYearsFunction: {
		id: 'work_years_function_id',
		label: {
			de: 'Anzahl Jahre in der Funktion tätig',
			en: 'Number of years active in the role',
			fr: "Nombre d'années d'expérience dans la fonction",
			it: 'Numero di anni di lavoro in questo ruolo',
			es: 'Número de años activo en esa función',
		},
		options: [
			{
				title: {
					de: 'weniger als 2 Jahre',
					en: 'Fewer than 2 years',
					fr: 'moins de 2 ans',
					it: 'Meno di 2 anni',
					es: 'Menos de 2 años',
				},
				value: 1,
			},
			{
				title: {
					de: '2 bis 4 Jahre',
					en: '2 to 4 years',
					fr: 'de 2 à 4 ans',
					it: 'Da 2 a 4 anni',
					es: '2 a 4 años',
				},
				value: 2,
			},
			{
				title: {
					de: '5 bis 10 Jahre',
					en: '5 to 10 years',
					fr: 'de 5 à 10 ans',
					it: 'Da 5 a 10 anni',
					es: '5 a 10 años',
				},
				value: 3,
			},
			{
				title: {
					de: 'über 10 Jahre',
					en: 'More than 10 years',
					fr: 'plus de 10 ans',
					it: 'Più di 10 anni',
					es: 'Más de 10 años',
				},
				value: 4,
			},
		],
	},
	workYearsIndustry: {
		id: 'work_years_industry_id',
		label: {
			de: 'Anzahl Jahre in der Branche tätig',
			en: 'Number of years active in the industry',
			fr: "Nombre d'années d'expérience dans le secteur",
			it: 'Numero di anni di attività nel settore',
			es: 'Número de años activo en el sector',
		},
		options: [
			{
				title: {
					de: 'weniger als 2 Jahre',
					en: 'Fewer than 2 years',
					fr: 'moins de 2 ans',
					it: 'Meno di 2 anni',
					es: 'Menos de 2 años',
				},
				value: 1,
			},
			{
				title: {
					de: '2 bis 4 Jahre',
					en: '2 to 4 years',
					fr: 'de 2 à 4 ans',
					it: 'Da 2 a 4 anni',
					es: '2 a 4 años',
				},
				value: 2,
			},
			{
				title: {
					de: '5 bis 10 Jahre',
					en: '5 to 10 years',
					fr: 'de 5 à 10 ans',
					it: 'Da 5 a 10 anni',
					es: '5 a 10 años',
				},
				value: 3,
			},
			{
				title: {
					de: 'über 10 Jahre',
					en: 'More than 10 years',
					fr: 'plus de 10 ans',
					it: 'Più di 10 anni',
					es: 'Más de 10 años',
				},
				value: 4,
			},
		],
	},
	management: {
		id: 'management_id',
		label: {
			de: 'Führungsspanne',
			en: 'Manager-to-staff ratio',
			fr: 'Nombre de personnes subordonnées',
			it: 'Rapporto manager/personale',
			es: 'Proporción de personal por jefe',
		},
		options: [
			{
				title: {
					de: 'keine Führungsverantwortung',
					en: 'No management responsibility',
					fr: 'pas de responsabilité de direction',
					it: 'Nessuna responsabilità di gestione',
					es: 'Sin responsabilidad directiva',
				},
				value: 1,
			},
			{
				title: {
					de: '1-5',
					en: '1-5',
					fr: '1-5',
					it: '1-5',
					es: '1-5',
				},
				value: 2,
			},
			{
				title: {
					de: '6-10',
					en: '6-10',
					fr: '6-10',
					it: '6-10',
					es: '6-10',
				},
				value: 3,
			},
			{
				title: {
					de: '11-20',
					en: '11-20',
					fr: '11-20',
					it: '11-20',
					es: '11-20',
				},
				value: 4,
			},
			{
				title: {
					de: '21-50',
					en: '21-50',
					fr: '21-50',
					it: '21-50',
					es: '21-50',
				},
				value: 5,
			},
			{
				title: {
					de: '51-100',
					en: '51-100',
					fr: '51-100',
					it: '51-100',
					es: '51-100',
				},
				value: 6,
			},
			{
				title: {
					de: '101 und mehr',
					en: 'More than 100',
					fr: 'plus de 100',
					it: 'Più di 100',
					es: 'Más de 100',
				},
				value: 7,
			},
		],
	},

	country: {
		id: 'country_id',
		label: {
			de: 'Land in dem Sie derzeit arbeiten',
			en: 'Land in dem Sie derzeit arbeiten',
			fr: 'Land in dem Sie derzeit arbeiten',
			it: 'Land in dem Sie derzeit arbeiten',
			es: 'Land in dem Sie derzeit arbeiten',
		},
		favorites: [
			{
				title: {
					de: 'Schweiz',
					en: 'Switzerland',
					fr: 'Suisse',
					it: 'Svizzera',
					es: 'Suiza',
				},
				value: 216,
			},
			{
				title: {
					de: 'Deutschland',
					en: 'Germany',
					fr: 'Allemagne',
					it: 'Germania',
					es: 'Alemania',
				},
				value: 84,
			},
			{
				title: {
					de: 'Österreich',
					en: 'Austria',
					fr: 'Autriche',
					it: 'Austria',
					es: 'Austria',
				},
				value: 14,
			},
		],
		options: countries,
	},

	workplace: {
		id: 'workplace', // Special case, since workplace can be id or custom text
		label: {
			de: 'Aktueller Arbeitsort',
			en: 'Work location',
			fr: 'Lieu de travail',
			it: 'Ubicazione del lavoro',
			es: 'Lugar de trabajo',
		},
		options: cantons,
	},
	jobFunction: {
		id: 'job_function',
		label: {
			de: 'Funktion',
			en: 'Role',
			fr: 'Fonction',
			it: 'Ruolo',
			es: 'Papel',
		},
	},
	jobTitle: {
		id: 'job_title',
		label: {
			de: 'Genaue Stellenbezeichnung',
			en: 'Exact designation of position',
			fr: 'Intitulé du poste',
			it: 'Designazione esatta della posizione',
			es: 'Designación exacta del puesto',
		},
		placeholder: {
			de: 'z. B. Senior Frontend Developer',
			en: 'e.g. Senior front end developer',
			fr: 'Exemple: Développeur Front-End Senior',
			it: 'ad es. sviluppatore senior front - end',
			es: 'ej. Desarrollador principal de Frontend',
		},
		optional: true,
	},
	monthlySalary: {
		id: 'monthly_salary',
		label: {
			de: 'Jetziger Bruttomonatslohn',
			en: 'Current monthly gross salary',
			fr: 'Salaire mensuel brut actuel',
			it: 'Salario mensile lordo attuale',
			es: 'Salario mensual bruto actual',
		},
		placeholder: {
			de: '0',
			en: '0',
			fr: '0',
			it: '0',
			es: '0',
		},
	},
	yearlySalary: {
		id: 'yearly_salary',
		label: {
			de: 'Aktueller Bruttojahreslohn (inkl. max. Bonus)',
			en: 'Current annual gross salary (incl. max. bonus)',
			fr: 'Salaire annuel brut actuel (y compris la prime max.)',
			it: 'Attuale stipendio annuale lordo (incl. bonus massimo)',
			es: 'Salario bruto anual actual (incl. bonificación máx.)',
		},
		placeholder: {
			de: '0',
			en: '0',
			fr: '0',
			it: '0',
			es: '0',
		},
	},

	// Step 4
	gender: {
		id: 'gender_id',
		label: {
			de: 'Geschlecht',
			en: 'Gender',
			fr: 'Sexe',
			it: 'Sesso',
			es: 'Sexo',
		},
		radios: {
			radio1: {
				id: 'female',
				label: {
					de: 'Frau',
					en: 'Female',
					fr: 'Femme',
					it: 'Femminile',
					es: 'Mujer',
				},
				value: '2',
			},
			radio2: {
				id: 'male',
				label: {
					de: 'Mann',
					en: 'Male',
					fr: 'Homme',
					it: 'Maschile',
					es: 'Varón',
				},
				value: '1',
			},
		},
	},
	yearOfBirth: {
		id: 'year_of_birth',
		label: {
			de: 'Geburtsjahr',
			en: 'Year of birth',
			fr: 'Année de naissance',
			it: 'Anno di nascita',
			es: 'Año de nacimiento',
		},
		placeholder: {
			de: 'z. B. 1972',
			en: 'e.g. 1972',
			fr: 'Exemple: 1972',
			it: 'ad es. 1972',
			es: 'e.g. 1972',
		},
	},
	nationality: {
		id: 'nationality_id',
		label: {
			de: 'Nationalität',
			en: 'Nationality',
			fr: 'Nationalité',
			it: 'Nazionalità',
			es: 'Nacionalidad',
		},
		favorites: [
			{
				title: {
					de: 'Schweiz',
					en: 'Switzerland',
					fr: 'Suisse',
					it: 'Svizzera',
					es: 'Suiza',
				},
				value: 216,
			},
			{
				title: {
					de: 'Deutschland',
					en: 'Germany',
					fr: 'Allemagne',
					it: 'Germania',
					es: 'Alemania',
				},
				value: 84,
			},
			{
				title: {
					de: 'Österreich',
					en: 'Austria',
					fr: 'Autriche',
					it: 'Austria',
					es: 'Austria',
				},
				value: 14,
			},
		],
		options: countries,
	},
	email: {
		id: 'email',
		label: {
			de: 'E-Mail-Adresse',
			en: 'E-mail address',
			fr: 'Adresse e-mail',
			it: 'Indirizzo e-mail',
			es: 'Correo electrónico',
		},
		placeholder: {
			de: 'z. B. max@mustermann.ch',
			en: 'e.g. max@mustermann.ch',
			fr: 'Exemple: max@mustermann.ch',
			it: 'ad es. max@mustermann.ch',
			es: 'ej. max@mustermann.ch',
		},
	},
	specifiedChildren: {
		id: 'specified_children',
		label: {
			de: 'Familiendaten erfassen, um die kompletten Lohndaten mit Familienzulagen zu erhalten <i>(nur für schweizer Bürger)</i>',
			en: 'Enter family data to obtain complete wage data with family allowances <i>(only for Swiss citizens)</i>',
			fr: 'Saisir les données familiales pour obtenir des données salariales complètes avec les allocations familiales <i>(pour les citoyens suisses uniquement)</i>',
			it: 'Inserire i dati della famiglia per ottenere dati salariali completi con assegni familiari <i>(solo per cittadini svizzeri)</i>',
			es: 'Introducir los datos de la familia para obtener los datos salariales completos con los complementos familiares <i>(sólo para ciudadanos suizos)</i>',
		},
	},
	residence: {
		id: 'residence_id',
		label: {
			de: 'Wohnort',
			en: 'Residence',
			fr: 'Résidence',
			it: 'Residenza',
			es: 'Residencia',
		},
		options: cantons,
	},
	children: {
		childBirthday: {
			label: {
				de: 'Geburtsdatum Kind #{PLACEHOLDER}',
				en: 'Date of birth child #{PLACEHOLDER}',
				fr: "Date de naissance de l'enfant #{PLACEHOLDER}",
				it: 'Data di nascita del bambino #{PLACEHOLDER}',
				es: 'Fecha de nacimiento del niño #{PLACEHOLDER}',
			},
		},
	},
	privacyPolicy: {
		id: 'privacy_policy',
		label: {
			de: 'Ich habe sowohl die <a href="/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzbestimmung</a> als auch die <a href="/agb" target="_blank" rel="noopener noreferrer">AGB</a> gelesen und stimme diesen zu.',
			en: 'I have read both the <a href="/datenschutz" target="_blank" rel="noopener noreferrer">Data Protection Clause</a> as well as the <a href="/agb" target="_blank" rel="noopener noreferrer">General Terms and Conditions</a> and agree to them.',
			fr: 'Je déclare avoir lu et accepté la <a href="/datenschutz" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a> ainsi que les <a href="/agb" target="_blank" rel="noopener noreferrer">Conditions générales</a>.',
			it: 'Ho letto la <a href="/datenschutz" target="_blank" rel="noopener noreferrer">clausola sulla protezione dei dati</a> ed i <a href="/agb" target="_blank" rel="noopener noreferrer">Termini e condizioni generali</a> e li accetto.',
			es: 'He leído tanto la <a href="/datenschutz" target="_blank" rel="noopener noreferrer">cláusula de protección de datos</a> como los <a href="/agb" target="_blank" rel="noopener noreferrer">términos y condicione generales </a> y estoy de acuerdo con ellos.',
		},
	},
	newsletter: {
		id: 'newsletter',
		label: {
			de: 'Für Lohncheck Newsletter anmelden',
			en: 'Register for the Paycheck Newsletter',
			fr: "S'inscrire à la newsletter de Lohncheck",
			it: 'Iscriviti alla newsletter sulle retribuzioni',
			es: 'Regístrese para recibir el boletín de Paycheck',
		},
	},
}

export default data
