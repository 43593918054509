import React from 'react'
import Modal from 'react-modal'
import { Button } from '@components'
import { Translate } from 'react-localize-redux'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		// marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '600px',
	},
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

function ModalComponent({ isOpen, onConfirm, onCancel }) {
	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00'
	}

	return (
		<Translate>
			{({ translate }) => (
				<div>
					<Modal
						isOpen={isOpen}
						onAfterOpen={afterOpenModal}
						onRequestClose={onCancel}
						style={customStyles}
						contentLabel="Example Modal"
						ariaHideApp={false}
					>
						<button
							style={{
								position: 'absolute',
								right: '10px',
								top: '10px',
							}}
							onClick={onCancel}
						>
							x
						</button>
						<h2>{translate('consentModalHeadline')}</h2>

						<div>
							<p>
								Hiermit willige ich ein, dass die Matto-Group
								meine zuvor gemachten Angaben an Öe24
								bekanntgibt. Die Matto-Group und Öe24 können
								meine Angaben auch in pseudonymisierter Form an
								Unternehmen in Bereich der Werbetechnologie wie
								Decentric, The Trade Desk, 1PlusX, Meta oder
								Google übermitteln, um mir als Teil einer
								bestimmten Zielgruppe (z. B. Berufs-,
								Einkommens- oder Altersgruppe) personalisierte
								Angebote online anzeigen zu können, d. h. auf
								Partner-Webseiten. Ihre Einwilligung können Sie
								jederzeit mit Wirkung für die Zukunft
								widerrufen. Die Datenbearbeitung der Matto-Group
								wird in Ziff. 3 der Matto-Group{' '}
								<a
									href="https://lohncheck.ch/de/datenschutz"
									target="_blank"
									rel="noopener noreferrer"
								>
									Datenschutzerklärung erläutert
								</a>
								, jene von Öe24 in der Datenschutzerklärung von{' '}
								<a
									href="https://www.oe24.at/service/datenschutzerklaerung-seite-1/333861151"
									target="_blank"
									rel="noopener noreferrer"
								>
									Öe24
								</a>
								. Für die Datenerhebung sind die Matto-Group und
								Öe24 gemeinsam verantwortlich. Die weitere
								Bearbeitung Ihrer Daten erfolgt durch die
								Matto-Group und Öe24 jeweils in eigener
								Verantwortlichkeit und nach Massgabe ihrer
								jeweiligen Datenschutzbestimmungen.
							</p>
							<p>
								«Pseudonymisiert» bedeutet, dass keine
								Rückschlüsse auf Ihre Person möglich sind,
								sofern Sie bei Google, einem Publisher wie der
								TX, Ringier, oder Audienzz – z. B. aufgrund
								eines Logins – nicht bereits bekannt sind.
							</p>
							<div style={{ float: 'right' }}>
								<Button
									style={{ marginRight: '10px' }}
									label={translate('decline')}
									type="button"
									onClick={onCancel}
								/>
								<Button
									label={translate('agree')}
									type="button"
									onClick={onConfirm}
								/>
							</div>
						</div>
					</Modal>
				</div>
			)}
		</Translate>
	)
}

export default ModalComponent
