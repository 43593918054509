import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Step1 from '@pages/Steps/Step1'
import Step2 from '@pages/Steps/Step2'
import Step3 from '@pages/Steps/Step3'
import Step4 from '@pages/Steps/Step4'
import Result from '@pages/Result'
import Legal from '@pages/Legal'
import Privacy from '@pages/Privacy'
import Conditions from '@pages/Conditions'

const Main = () => (
	<main className="main">
		<Switch>
			<Route exact path="/" component={Step1}></Route>
			<Route exact path="/arbeitgeber" component={Step2}></Route>
			<Route exact path="/eigene-daten" component={Step3}></Route>
			<Route exact path="/persoenliches" component={Step4}></Route>
			<Route exact path="/ergebnis" component={Result}></Route>
			<Route exact path="/impressum" component={Legal}></Route>
			<Route exact path="/datenschutz" component={Privacy}></Route>
			<Route exact path="/agb" component={Conditions}></Route>
		</Switch>
	</main>
)

export default Main
